import { Suspense, lazy, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useLocation } from 'react-router-dom';

import './header.css';
import './responsive.css';
import URL from '../../app/constants/urls';
import logo from '../assets/img/logo.webp';
import UI from '../../app/constants/ui';
import Loader from '../../app/components/loader';

const AIQuestionForm = lazy(() =>
  import('../../app/pages/createAssessment/AIQuestionForm')
);

function Header() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [shouldShowDialog, setShouldShowDialog] = useState(false);

  const [forEmployers, setForEmployers] = useState(false);

  const handleLinkClick = () => {
    setForEmployers(!forEmployers);
  };

  const location = useLocation();

  const renderTheNavItemNames = () => {
    if (
      location.pathname === URL.JOB_SEEKER ||
      location.pathname === URL.HOME
    ) {
      return UI.FOR_RECRUITERS;
    }
    return UI.FOR_JOB_SEEKERS;
  };

  const renderTheNavItemlink = () => {
    if (
      location.pathname === URL.JOB_SEEKER ||
      location.pathname === URL.HOME
    ) {
      return URL.RECRUITERS;
    }
    return URL.JOB_SEEKER;
  };

  const onClickOnTryAIAssessor = () => {
    setShouldShowDialog(true);
  };
  const handleCloseTryAIAssessor = () => {
    setShouldShowDialog(false);
  };
  return (
    <>
      <header
        className="theme_header"
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1000,
          boxShadow: '0 1px 2px rgba(0,0,0,.12)',
        }}
      >
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <Link to="/">
              <img
                src={logo}
                alt={UI.ALT_ALIGNMYCAREER}
                width="70%"
                height="100%"
                loading="lazy"
                title={UI.ALT_ALIGNMYCAREER}
              />
            </Link>
            <Button
              variant="primary"
              onClick={handleShow}
              className="nav_toggler"
            >
              <span className="navbar-toggler-icon" />
            </Button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <form className="d-flex" role="search">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <div style={{ marginBottom: 8, marginRight: 10 }}>
                    <span
                      style={{
                        color: '#0d6efd',
                        fontSize: 18,
                        cursor: 'pointer',
                      }}
                      onClick={onClickOnTryAIAssessor}
                    >
                      {UI.TRY_AI_ASSESSOR}
                    </span>
                  </div>

                  <li className="nav-item">
                    <Link to={renderTheNavItemlink()} onClick={handleLinkClick}>
                      {renderTheNavItemNames()}
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to={URL.LOGIN}
                      className="transparent-btn"
                      rel="noopener noreferrer"
                    >
                      {UI.LOGIN_HOME_PAGE}
                      <span /> <span /> <span /> <span />
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={URL.REGISTER}
                      className="white-btn"
                      rel="noopener noreferrer"
                    >
                      {UI.SIGNUP}
                      <span /> <span /> <span /> <span />
                    </Link>
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </nav>

        {!shouldShowDialog && (
          <Offcanvas show={show} onHide={handleClose} className="header-css">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                {/* <a className="navbar-brand" href="/">
                <img src="/assets/img/logo/logo.webp" alt="logo" />
              </a> */}
                <Link to="/" className="navbar-brand">
                  <img
                    src={logo}
                    alt={UI.ALT_ALIGNMYCAREER}
                    loading="lazy"
                    title={UI.ALT_ALIGNMYCAREER}
                  />
                </Link>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <nav className="navbar navbar-expand-lg mobile_menu">
                <div className="container w-100">
                  <form className="d-flex w-100" role="search">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 w-100">
                      <li className="nav-item list">
                        <Link
                          to={renderTheNavItemlink()}
                          onClick={handleLinkClick}
                          className="nav-link navigation"
                          aria-current="page"
                        >
                          {renderTheNavItemNames()}
                        </Link>
                        <div style={{ marginBottom: 5 }}>
                          <span
                            style={{
                              color: '#0d6efd',
                              fontSize: 18,
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setShow(false);
                              onClickOnTryAIAssessor();
                            }}
                          >
                            {UI.TRY_AI_ASSESSOR}
                          </span>
                        </div>
                      </li>

                      <div className="d-flex mobile-button">
                        <li className="nav-item ">
                          <Link
                            to={URL.LOGIN}
                            className="transparent-btn"
                            rel="noopener noreferrer"
                          >
                            {UI.LOGIN_HOME_PAGE}
                            <span /> <span /> <span /> <span />
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to={URL.REGISTER}
                            className="white-btn"
                            rel="noopener noreferrer"
                          >
                            {UI.SIGNUP}
                            <span /> <span /> <span /> <span />
                          </Link>
                        </li>
                      </div>
                    </ul>
                  </form>
                </div>
              </nav>
            </Offcanvas.Body>
          </Offcanvas>
        )}
      </header>

      {shouldShowDialog && (
        <Suspense fallback={<Loader />}>
          <AIQuestionForm
            shouldOpenDialog={shouldShowDialog}
            handleClose={handleCloseTryAIAssessor}
          />
        </Suspense>
      )}
    </>
  );
}

export default Header;
