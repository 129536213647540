import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getRequest } from '../../../app/services';
// import calendar from '../../assets/img/calendar.webp';
// import black from '../../assets/img/black.webp';
import { MAINPAGE_API_URL } from '../../../app/constants/apiUrls';
import UI from '../../../app/constants/ui';

function Blogs({ role }) {
  const [blogdata, setBlogdata] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState();
  const fetchBlogData = () => {
    getRequest(`${MAINPAGE_API_URL.MAINPAGE_BLOGS}${role}`).then((data) => {
      setBlogdata(data);
      setSelectedBlog(data[0]);
    });
  };

  useEffect(() => {
    if (!role) return;
    fetchBlogData();
  }, [role]);

  const handleBlogClick = (blog) => {
    setSelectedBlog(blog);
  };

  return (
    <div>
      {blogdata.length > 0 ? (
        <div className="section latest_blog Blog_comp">
          <div className="container">
            <div className="section-heading">
              <h3 className="section_head">{UI.BLOG_SECTION_TITLE}</h3>
            </div>
            <div className="blog_inner">
              <div className="d-flex align-items-start w-100">
                <div className="row w-100">
                  <div className="col-lg-6 ">
                    <div className="tab-content" id="v-pills-tabContent">
                      {selectedBlog && (
                        <div
                          className="tab-pane fade show active"
                          role="tabpanel"
                          tabIndex="0"
                        >
                          <div
                            className="blog_content"
                            style={{
                              backgroundImage: `url('${selectedBlog.image}')`,
                            }}
                          >
                            <div className="blog_inner_wrap">
                              <h6 style={{ marginBottom: 10 }}>
                                {selectedBlog.title}
                              </h6>
                              {/* <p>
                                <img
                                  src={calendar}
                                  alt={UI.ALT_BLOG_CALENDAR_IMAGE}
                                  width="24px"
                                  height="24px"
                                />
                                {selectedBlog.date}
                              </p> */}
                              <a
                                className="blue-btn read_blog"
                                href={selectedBlog.blogurl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {UI.READ_MORE}
                                <span /> <span /> <span /> <span />
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 ">
                    <div
                      className="nav flex-column nav-pills me-3"
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      {blogdata.map((blog, index) => (
                        <button
                          className={
                            index === 0 ? 'nav-link active' : 'nav-link'
                          }
                          id={`pills-home-tab${index}`}
                          onClick={() => handleBlogClick(blog)}
                          type="button"
                          role="tab"
                          aria-controls={`#pills-home${index}`}
                          aria-selected="true"
                          key={index}
                        >
                          <div className="blog_head">
                            <div className="row">
                              <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                                <div className="blog_img p-1">
                                  <img
                                    src={blog.image}
                                    alt={UI.ALT_BLOG_IMAGE}
                                    width="100%"
                                    height="100%"
                                    loading="lazy"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                                <div className="blog_right">
                                  {/* <p>
                                    <img
                                      src={black}
                                      alt={UI.ALT_BLOG_CALENDAR_IMAGE}
                                      width="24px"
                                      height="24px"
                                    />
                                    {blog.date}
                                  </p> */}
                                  <h6>{blog.title}</h6>
                                  {/* <p>
                            <span>{blog.description}</span>
                          </p> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

Blogs.propTypes = {
  role: PropTypes.number.isRequired,
};
export default Blogs;
