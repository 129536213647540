import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import noop from 'lodash/noop';
import Button from '@mui/material/Button';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { Lock } from '@mui/icons-material';
import styles from './PointsSummary.module.css';
import ErrorSnackBar from '../../components/snackBar/ErrorSnackBar';
import { getRequest } from '../../services';
import { getCouponUrl } from '../../constants/apiUrls';
import VALIDATION_VALUES from '../../constants/validationValues';
import getTotalAvailablePoints from '../../helpers/totalAvailablePoints';
import ERROR_MESSAGE from '../../constants/errorMsgs';
import { CommonSkeleton } from '../../components/skeleton';
import TransactionHistory from './transactionHistory/TransactionHistory';
import { getFormattedNumber } from '../../formatter/number';
import UI from '../../constants/ui';
import { getFormattedDate } from '../../formatter/date';
import {
  getAvailablePointsUser,
  getTransactionHistoryUser,
} from '../../../redux/apiCalls';
import hasPermission from '../../hooks/hasPermission';
import { PERMISSIONS_ACTIONS } from '../../formatter/permissions.bootstrap';
import {
  getLoginDetailFromSession,
  getPlanDetails,
} from '../../helpers/sessionDetails';
import LOGIN_TYPE from '../../constants/loginType';
import URL from '../../constants/urls';
import { setButtonActionWhilePayment } from '../../../redux/userSlice';
import {
  BUTTON_TYPE,
  PAY_PER_USER_PLANID,
} from '../../constants/pricingDashboard';

function PointsSummary(props) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const {
    onOpenBuyPointDialog,
    isNewPointUpdated,
    shouldShowViewTranscations,
    setIsDialogOpen,
  } = props;
  const points = useSelector((state) => state.user.userInfo.availablePoints);
  const dispatch = useDispatch();

  const [isPointsSkeletonShow, setIsPointsSkeletonShow] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const planDetails = getPlanDetails();

  const [isTransactionSkeletonShow, setIsTransactionSkeletonShow] =
    useState(true);
  const [isTransactionHistoryTableOpen, setIsTransactionHistoryTableOpen] =
    useState(false);
  const [
    isTransactionHistoryAlreadyLoaded,
    setIsTransactionHistoryAlreadyLoaded,
  ] = useState(false);

  const [opensnackbar, setSnackbarOpen] = useState();

  const getTransactionHistory = () => {
    setIsTransactionSkeletonShow(true);
    getTransactionHistoryUser(dispatch)
      .catch((error) => {
        setSnackbarOpen({
          setopen: true,
          message: error.msg,
          severity: 'error',
        });
      })
      .finally(() => {
        setIsTransactionSkeletonShow(false);
      });
  };

  useEffect(() => {
    if (isTransactionHistoryTableOpen && !isTransactionHistoryAlreadyLoaded) {
      getTransactionHistory();
      setIsTransactionHistoryAlreadyLoaded(true);
    }
  }, [isTransactionHistoryTableOpen]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen({ setopen: false });
  };

  const getPointsByCouponCode = (data) => {
    setIsLoading(true);

    getRequest(getCouponUrl(data.redeemfreepoints))
      .then(() => {
        getAvailablePointsUser(dispatch);
        setSnackbarOpen({
          setopen: true,
          message: UI.COUPON_CODE_APPLIED,
          severity: 'success',
        });
        if (isTransactionHistoryTableOpen) {
          getTransactionHistory();
        }
        setValue('redeemfreepoints', undefined);
      })
      .catch((error) => {
        setSnackbarOpen({
          setopen: true,
          message: error.msg,
          severity: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getPoints = () => {
    if (isTransactionHistoryTableOpen) {
      getTransactionHistory();
    }

    setIsPointsSkeletonShow(true);

    getAvailablePointsUser(dispatch)
      .catch((error) => {
        setSnackbarOpen({
          setopen: true,
          message: error.msg,
          severity: 'error',
        });
      })
      .finally(() => {
        setIsPointsSkeletonShow(false);
      });
  };

  useEffect(() => {
    if (isNewPointUpdated) {
      getPoints();
    }
  }, [isNewPointUpdated]);

  const reRenderPermissionsCode = useSelector(
    (state) => state.user.reRenderPermissionsCode
  );

  const hasPaymentPermission = useMemo(
    () => hasPermission(PERMISSIONS_ACTIONS.PAYMENT),
    [reRenderPermissionsCode]
  );
  const hasCouponApplyPermission = useMemo(
    () => hasPermission(PERMISSIONS_ACTIONS.COUPON_APPLY),
    [reRenderPermissionsCode]
  );

  const loginDetails = getLoginDetailFromSession();
  const navigate = useNavigate();

  return (
    <div className="container">
      {isPointsSkeletonShow ? (
        <CommonSkeleton />
      ) : (
        <div>
          <div className="headline-5-bold color-000000 my-2">
            {UI.CREDIT_POINTS}
          </div>
          <div className="card-border">
            <div className="row">
              {planDetails && loginDetails?.role === LOGIN_TYPE.EMPLOYER && (
                <div className={styles.planSection}>
                  <span>
                    {UI.ACTIVE_PLAN}: {planDetails?.name}
                  </span>
                  <div>
                    {loginDetails?.planid !== PAY_PER_USER_PLANID && (
                      <Button
                        variant="contained"
                        className="m-2"
                        onClick={() => {
                          dispatch(
                            setButtonActionWhilePayment(BUTTON_TYPE.RENEW)
                          );
                          setIsDialogOpen(true);
                        }}
                      >
                        {UI.RENEW}
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      className="m-2"
                      startIcon={!hasPaymentPermission && <Lock />}
                      onClick={() => {
                        dispatch(
                          setButtonActionWhilePayment(BUTTON_TYPE.ADD_POINTS)
                        );
                        setIsDialogOpen(true);
                      }}
                      disabled={!hasPaymentPermission}
                    >
                      {UI.ADD_POINTS}
                    </Button>
                    <Button
                      variant="contained"
                      className="m-2"
                      onClick={() => navigate(URL.PRICING_DASHBOARD)}
                    >
                      {UI.UPGRADE_PLAN}
                    </Button>
                  </div>
                  <div />
                  <div />
                </div>
              )}
              <div className="col-sm-4 mb-3">
                {/* <div className="d-flex justify-content-center mt-4">
                  <span className="body-2 color-000000 align-self-center">
                    {UI.AVAILABLE_POINTS}
                  </span>
                  <span className="color-AAAAAA ms-1">
                    <InfoOutlinedIcon />
                  </span>
                </div> */}

                <div className="d-flex justify-content-center body-2 color-000000 mt-4">
                  {UI.AVAILABLE_POINTS}
                </div>

                <div className="d-flex justify-content-center mt-3 headline-3 color-000000">
                  {getFormattedNumber(getTotalAvailablePoints(points))}
                </div>

                {loginDetails?.role === LOGIN_TYPE.CANDIDATE && (
                  <div className="d-flex justify-content-center pt-3 mt-sm-2 mt-md-4">
                    <Button
                      onClick={() => {
                        onOpenBuyPointDialog(true);
                      }}
                      startIcon={!hasPaymentPermission && <Lock />}
                      variant="contained"
                      disabled={!hasPaymentPermission}
                    >
                      {UI.BUY_POINTS}
                    </Button>
                  </div>
                )}
              </div>
              <div className="col-sm-8 mb-3">
                {/* <div className=" mt-4 text-center text-md-start">
                  <span className="body-2 color-000000">{UI.FREE_POINTS} </span>
                  <span className="color-AAAAAA ms-1">
                    <InfoOutlinedIcon />
                  </span>
                </div> */}
                <div className="row">
                  <div className="col-sm-auto d-flex flex-column align-items-center">
                    <div className="body-2 color-000000 mt-4 text-center text-md-start">
                      {UI.FREE_POINTS}
                    </div>

                    <div className="mt-3  text-center text-md-start">
                      <span className="headline-3 color-000000">
                        {getFormattedNumber(points?.freepoints?.point) || 0}
                      </span>
                    </div>

                    <div>
                      {points?.freepoints && (
                        <span className="caption color-5B5B5B">
                          {UI.EXPIRE}:{' '}
                          {getFormattedDate(points?.freepoints?.expiry)}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-sm-auto d-flex flex-column align-items-center">
                    <div className="body-2 color-000000 mt-4 text-center text-md-start">
                      {UI.PURCHASED_POINTS}
                    </div>

                    <div className="mt-3  text-center text-md-start">
                      <span className="headline-3 color-000000">
                        {getFormattedNumber(points?.purchasedpoints?.point) ||
                          0}
                      </span>
                    </div>

                    <div>
                      {points?.purchasedpoints && (
                        <span className="caption color-5B5B5B">
                          {UI.EXPIRE}:{' '}
                          {getFormattedDate(points?.purchasedpoints?.expiry)}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.couponSection}>
                <label
                  htmlFor="free-points"
                  className="mt-3 subtitle-1 color-000000 mb-3"
                >
                  {UI.REDEEM_FREE_POINTS}
                </label>
                <div className="col-sm-6">
                  <TextField
                    {...register('redeemfreepoints', {
                      required: ERROR_MESSAGE.REQ_ERROR_MSG,

                      maxLength: {
                        value: VALIDATION_VALUES.MAX_VALUE_40,
                        message:
                          ERROR_MESSAGE.MAX_ERROR_MSG +
                          VALIDATION_VALUES.MAX_VALUE_40,
                      },
                    })}
                    placeholder={UI.FREE_CODE_PLACEHOLDER}
                    fullWidth
                    id="free-points"
                    size="small"
                    error={!!errors.redeemfreepoints?.message}
                    disabled={!hasPermission(PERMISSIONS_ACTIONS.COUPON_APPLY)}
                  />
                  <FormHelperText error>
                    <span> </span>
                    {errors.redeemfreepoints?.message}
                  </FormHelperText>
                </div>

                <div>
                  <Button
                    onClick={handleSubmit(getPointsByCouponCode)}
                    type="button"
                    variant="outlined"
                    disabled={isLoading || !hasCouponApplyPermission}
                    startIcon={
                      (isLoading && (
                        <CircularProgress size="1rem" color="inherit" />
                      )) ||
                      (!hasCouponApplyPermission && <Lock />)
                    }
                  >
                    {UI.APPLY_BUTTON}
                  </Button>
                </div>
                <div />
                <div />
              </div>
            </div>
          </div>
          {shouldShowViewTranscations && (
            <TransactionHistory
              setOpen={setIsTransactionHistoryTableOpen}
              // transaction={transaction}
              isTransactionSkeletonShow={isTransactionSkeletonShow}
              opensnackbar={opensnackbar}
              handleClose={handleClose}
              open={isTransactionHistoryTableOpen}
            />
          )}
        </div>
      )}
      <ErrorSnackBar opensnackbar={opensnackbar} handleClose={handleClose} />
    </div>
  );
}

PointsSummary.propTypes = {
  onOpenBuyPointDialog: PropTypes.func,
  isNewPointUpdated: PropTypes.bool,
  shouldShowViewTranscations: PropTypes.bool,
  setIsDialogOpen: PropTypes.func,
  setTypeofPurchaseofButton: PropTypes.func,
};
PointsSummary.defaultProps = {
  onOpenBuyPointDialog: noop,
  isNewPointUpdated: false,
  shouldShowViewTranscations: true,
  setIsDialogOpen: noop,
  setTypeofPurchaseofButton: noop,
};
export default PointsSummary;
