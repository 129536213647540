import React, { useEffect, useState, useMemo, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import PostAddIcon from '@mui/icons-material/PostAdd';
import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
// import MenuItem from '@mui/material/MenuItem';
import { Box, Typography } from '@mui/material';
import { Lock } from '@mui/icons-material';
import '../../styles/styles.css';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import Offcanvas from 'react-bootstrap/Offcanvas';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  setAvailablePointsZero,
  setRequestsInComplete,
  setRerenderpermissions,
  setUserRole,
} from '../../../redux/userSlice';
import { getLoginDetailFromSession } from '../../helpers/sessionDetails';
import { postRequest } from '../../services';
import STATUS_CODE from '../../constants/statusCode';
import URL from '../../constants/urls';
import STORAGE_KEY from '../../constants/storageKey';
import LOGIN_TYPE from '../../constants/loginType';
import getCurrentHomeUrl from './navbarComponent.helper';
import styles from './navigationComponent.module.css';
import { API_URL, IMAGE_URL } from '../../constants/apiUrls';
import AvailablePoints from '../availablePoints';
import NotificationIcon from '../notifications/NotificationIcon';
import UI from '../../constants/ui';
import useMobileDevice, {
  ismallMobileDevice,
} from '../../hooks/useMobileDevice';
import useGAEventsTracker from '../../hooks/useGAEventsTracker';
import GA from '../../constants/analytics.constants';
import { PERMISSIONS_ACTIONS } from '../../formatter/permissions.bootstrap';
import hasPermission from '../../hooks/hasPermission';
import ICON_OTHER_ROUTES from '../../img/otherRoutes.svg';
import { EMPLOYER_LOGIN_ESTATE } from '../../constants/loginStates.constants';
import Loader from '../../components/loader/Loader';
import useWindowSize from '../../hooks/useWindowSize';
import { SMALL_MOBILE_SCREEN_WIDTH } from '../../constants/screenSize';

const AIQuestionForm = lazy(() => import('../createAssessment/AIQuestionForm'));

const GAEventsTracker = useGAEventsTracker(GA.EVENT_BUTTON.NAVIGATION);
const GALogoutEventsTracker = useGAEventsTracker(GA.EVENT_BUTTON.LOGOUT);

const KEBAB_LINK = `color-1F2830 ${styles.navLink} px-3 py-1 w-100 subtitle-1`;
const ACTIVE_KEBAB_LINK = styles.activeKebabLink;
const MENU_ITEM_STYLE = styles.menuitem;
const BREAK = styles.break;
const FIND_DIV = styles.findDiv;
const MAIN_DIV = styles.mainDiv;

function RenderNavBarButtons(props) {
  const { loggedInUserRole, activeUrl } = props;

  if (loggedInUserRole === LOGIN_TYPE.EMPLOYER) {
    return (
      <div>
        <Link
          to={URL.FIND_CANDIDATES}
          className={` color-1F2830 ${styles.navLink} ${
            activeUrl === URL.FIND_CANDIDATES
              ? `subtitle-1-bold `
              : 'subtitle-1'
          }`}
        >
          {UI.FIND_TALENT}
        </Link>
        {activeUrl === URL.FIND_CANDIDATES ? (
          <div className={`background-1D8FF2 ${styles.active}`} />
        ) : (
          ''
        )}
      </div>
    );
  }
  if (loggedInUserRole === LOGIN_TYPE.CANDIDATE) {
    return (
      <div>
        <Link
          to={URL.FIND_JOBS}
          className={`color-1F2830 ${styles.navLink} ${
            activeUrl === URL.FIND_JOBS
              ? `subtitle-1-bold ${styles.active}`
              : 'subtitle-1'
          }`}
        >
          {UI.FIND_JOBS}
        </Link>
        {activeUrl === URL.FIND_JOBS ? (
          <div className={`background-1D8FF2 ${styles.active}`} />
        ) : (
          ''
        )}
      </div>
    );
  }
  if (loggedInUserRole === LOGIN_TYPE.UNIVERSITY) {
    return (
      <div className="d-flex justify-content-center col-4 ">
        <div className={`color-1F2830 univ-headline span-together px-4 py-1 `}>
          {getLoginDetailFromSession()?.name}
        </div>
      </div>
    );
  }
  if (loggedInUserRole === LOGIN_TYPE.ADMIN) {
    return (
      <>
        <div className="me-3">
          <Link
            to={`${URL.ADMIN_ACTION}?action=site_map`}
            className={`color-1F2830 ${styles.navLink}  ${
              activeUrl === URL.ADMIN_ACTION
                ? `subtitle-1-bold ${styles.active}`
                : 'subtitle-1'
            }`}
          >
            {UI.ACTIONS}
          </Link>
          {activeUrl === URL.ADMIN_ACTION ? (
            <div className={`background-1D8FF2 ${styles.active}`} />
          ) : (
            ''
          )}
        </div>
        <div className="ms-3">
          <Link
            to={URL.ADMIN_ANALYTICS}
            className={`color-1F2830 ${styles.navLink} ${
              activeUrl === URL.ADMIN_ANALYTICS
                ? `subtitle-1-bold ${styles.active}`
                : 'subtitle-1'
            }`}
          >
            {UI.ANALYTICS}
          </Link>
          {activeUrl === URL.ADMIN_ANALYTICS ? (
            <div className={`background-1D8FF2 ${styles.active}`} />
          ) : (
            ''
          )}
        </div>
      </>
    );
  }
  return null;
}

function NavbarComponent(props) {
  const { loggedInUserRole, setLoggedInUserRole } = props;
  const dispatch = useDispatch();
  const reRenderPermissionsCode = useSelector(
    (state) => state.user.reRenderPermissionsCode
  );
  const [activeUrl, setActiveUrl] = useState(URL.FIND_JOBS);
  const [shouldShowDialog, setShouldShowDialog] = useState(false);
  const [shouldOpen, setShouldOpen] = useState(false);
  const notificationCount = useSelector(
    (state) => state.user.userInfo.notificationsCount
  );

  const location = useLocation();
  const navigate = useNavigate();

  const currentHomeUrl = useMemo(
    () => getCurrentHomeUrl(loggedInUserRole),
    [loggedInUserRole]
  );

  const isMobileDevice = useMobileDevice();
  const isSmallDevice = ismallMobileDevice();
  const { windowWidth } = useWindowSize();
  const project = useMemo(
    () => getLoginDetailFromSession()?.project,
    [reRenderPermissionsCode]
  );
  const isApprovalPending = useMemo(
    () =>
      getLoginDetailFromSession()?.estate ===
      EMPLOYER_LOGIN_ESTATE.APPROVAL_PENDING,
    [reRenderPermissionsCode]
  );

  const handleLogout = () => {
    const role = getLoginDetailFromSession()?.role;
    dispatch(setUserRole(role));
    postRequest(API_URL.LOGOUT)
      .then((res) => {
        if (res.code === STATUS_CODE.SUCCESSFULLY_LOGOUT) {
          // eslint-disable-next-line no-unsafe-optional-chaining
          GALogoutEventsTracker(getLoginDetailFromSession()?.name + GA.LOGOUT);
          localStorage.removeItem(STORAGE_KEY.CANDIDATE_DETAILS);
          localStorage.removeItem(STORAGE_KEY.EMPLOYER_DETAILS);
          localStorage.removeItem(STORAGE_KEY.SESSION_DETAILS);
          localStorage.removeItem(STORAGE_KEY.PRICE_PLAN_DETAILS);
          setLoggedInUserRole();
          if (role === LOGIN_TYPE.CANDIDATE) navigate(URL.HOME);
          else if (role === LOGIN_TYPE.EMPLOYER) {
            navigate(URL.RECRUITERS);
          }

          dispatch(setRequestsInComplete());
          dispatch(setRerenderpermissions());
          dispatch(setAvailablePointsZero());
          setShouldOpen(!shouldOpen);
        }
      })
      .catch(() => {});
  };

  const onSelectNavOption = (url) => {
    setShouldOpen(!shouldOpen);
    navigate(url);
  };

  const hasUpdateJobPermission = useMemo(
    () => hasPermission(PERMISSIONS_ACTIONS.UPDATE_JOB),
    [reRenderPermissionsCode]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    const { pathname } = location;
    // const firstPathName = getFirstPathName(pathname);
    setActiveUrl(pathname);
  }, [location.pathname]);

  const onClickAIQuestionForm = () => {
    setShouldShowDialog(true);
  };

  // Hamburger Menu Render
  const renderMenu = useMemo(
    () => (
      <Offcanvas
        show={shouldOpen}
        onHide={() => setShouldOpen(!shouldOpen)}
        placement={isMobileDevice ? 'start' : 'end'}
      >
        <Offcanvas.Header closeButton className="py-4">
          <Offcanvas.Title>
            <img
              src={IMAGE_URL.ALIGNMYCAREER}
              alt={UI.ALT_ALIGNMYCAREER}
              loading="lazy"
              title={UI.ALT_ALIGNMYCAREER}
              width="200px"
              height="100%"
            />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="pb-4">
          {loggedInUserRole === LOGIN_TYPE.EMPLOYER ? (
            <div>
              <div className="px-3 py-2 w-100 text-primary">
                <span className="px-3">
                  {getLoginDetailFromSession()?.name}
                </span>
              </div>

              <hr className="mt-1 mb-1" />
              {isMobileDevice && (
                <>
                  {(loggedInUserRole === LOGIN_TYPE.EMPLOYER ||
                    !loggedInUserRole) && (
                    <div
                      onClick={onClickAIQuestionForm}
                      className={classNames(MENU_ITEM_STYLE)}
                    >
                      <span
                        style={{ color: '#0d6efd', cursor: 'pointer' }}
                        className="px-3"
                      >
                        {UI.TRY_AI_ASSESSOR}
                      </span>
                    </div>
                  )}
                  <hr className="mt-1 mb-1" />
                  <div
                    onClick={() => onSelectNavOption(URL.FIND_CANDIDATES)}
                    className={classNames(MENU_ITEM_STYLE)}
                  >
                    <div
                      className={classNames(KEBAB_LINK, {
                        [ACTIVE_KEBAB_LINK]: activeUrl === URL.FIND_CANDIDATES,
                      })}
                    >
                      {UI.FIND_TALENT}
                    </div>
                  </div>
                  <hr className="my-2" />
                </>
              )}
              <div
                onClick={() => onSelectNavOption(URL.COMPANY_EMPLOYEE_PROFILE)}
                className={classNames(MENU_ITEM_STYLE)}
              >
                <div
                  to={URL.COMPANY_EMPLOYEE_PROFILE}
                  className={classNames(KEBAB_LINK, {
                    [ACTIVE_KEBAB_LINK]:
                      activeUrl === URL.COMPANY_EMPLOYEE_PROFILE,
                  })}
                >
                  {UI.PROFILE}
                </div>
              </div>
              <div
                onClick={() => onSelectNavOption(URL.EMPLOYER_PROFILE)}
                className={classNames(MENU_ITEM_STYLE)}
              >
                <div
                  to={URL.EMPLOYER_PROFILE}
                  className={classNames(KEBAB_LINK, {
                    [ACTIVE_KEBAB_LINK]: activeUrl === URL.EMPLOYER_PROFILE,
                  })}
                >
                  {UI.COMPANY_PROFILE}
                </div>
              </div>

              <div
                onClick={() => onSelectNavOption(URL.POST_JOB)}
                className={classNames(MENU_ITEM_STYLE)}
                disabled={!hasUpdateJobPermission}
              >
                <div
                  to={URL.POST_JOB}
                  className={classNames(KEBAB_LINK, {
                    [ACTIVE_KEBAB_LINK]: activeUrl === URL.POST_JOB,
                  })}
                  onClick={() => GAEventsTracker(UI.POST_JOB)}
                >
                  <div className="d-flex justify-content-between">
                    {UI.POST_JOB}
                    {!hasUpdateJobPermission && (
                      <Lock sx={{ height: '1.2rem' }} />
                    )}
                  </div>
                </div>
              </div>
              <div
                onClick={() => onSelectNavOption(URL.POSTED_JOBS)}
                className={classNames(MENU_ITEM_STYLE)}
                disabled={!project || isApprovalPending}
              >
                <div
                  to={URL.POSTED_JOBS}
                  className={classNames(KEBAB_LINK, {
                    [ACTIVE_KEBAB_LINK]: activeUrl === URL.POSTED_JOBS,
                  })}
                  onClick={() => GAEventsTracker(UI.POSTED_JOB)}
                >
                  <div className="d-flex justify-content-between">
                    {UI.POSTED_JOB}
                    {(!project || isApprovalPending) && (
                      <Lock sx={{ height: '1.2rem' }} />
                    )}
                  </div>
                </div>
              </div>

              <div
                onClick={() => onSelectNavOption(URL.MY_CANDIDATES)}
                className={classNames(MENU_ITEM_STYLE)}
                disabled={!project || isApprovalPending}
              >
                <div
                  to={URL.MY_CANDIDATES}
                  className={classNames(KEBAB_LINK, {
                    [ACTIVE_KEBAB_LINK]: activeUrl === URL.MY_CANDIDATES,
                  })}
                  onClick={() => GAEventsTracker(UI.MY_CANDIDATES)}
                >
                  <div className="d-flex justify-content-between">
                    {UI.MY_CANDIDATES}
                    {(!project || isApprovalPending) && (
                      <Lock sx={{ height: '1.2rem' }} />
                    )}
                  </div>
                </div>
              </div>

              {/* have to change the disabled logic depending on permissions */}
              <div
                onClick={() => onSelectNavOption(URL.EMPLOYER_ASSESSMENTS)}
                className={classNames(MENU_ITEM_STYLE)}
                disabled={!project || isApprovalPending}
              >
                <div
                  to={URL.EMPLOYER_ASSESSMENTS}
                  className={classNames(KEBAB_LINK, {
                    [ACTIVE_KEBAB_LINK]: activeUrl === URL.EMPLOYER_ASSESSMENTS,
                  })}
                >
                  <div className="d-flex justify-content-between">
                    {UI.ASSESSMENTS}
                    {(!project || isApprovalPending) && (
                      <Lock sx={{ height: '1.2rem' }} />
                    )}
                  </div>
                </div>
              </div>
              <div
                onClick={() => onSelectNavOption(URL.AI_ASSESSMENT)}
                className={classNames(MENU_ITEM_STYLE)}
                disabled={!project || isApprovalPending}
              >
                <div
                  to={URL.AI_ASSESSMENT}
                  className={classNames(KEBAB_LINK, {
                    [ACTIVE_KEBAB_LINK]: activeUrl === URL.AI_ASSESSMENT,
                  })}
                >
                  <div className="d-flex justify-content-between">
                    {UI.AI_ASSESSMENT_NAVBAR}
                    {(!project || isApprovalPending) && (
                      <Lock sx={{ height: '1.2rem' }} />
                    )}
                  </div>
                </div>
              </div>

              <div
                onClick={() => onSelectNavOption(URL.PROJECT_DETAILS)}
                className={classNames(MENU_ITEM_STYLE)}
                disabled={!project || isApprovalPending}
              >
                <div
                  to={URL.PROJECT_DETAILS}
                  className={classNames(KEBAB_LINK, {
                    [ACTIVE_KEBAB_LINK]: activeUrl === URL.PROJECT_DETAILS,
                  })}
                >
                  <div className="d-flex justify-content-between">
                    {UI.PROJECT_DETAILS}
                    {(!project || isApprovalPending) && (
                      <Lock sx={{ height: '1.2rem' }} />
                    )}
                  </div>
                </div>
              </div>
              <div
                onClick={() => onSelectNavOption(URL.MY_PROJECTS)}
                className={classNames(MENU_ITEM_STYLE)}
              >
                <div
                  to={URL.MY_PROJECTS}
                  className={classNames(KEBAB_LINK, {
                    [ACTIVE_KEBAB_LINK]: activeUrl === URL.MY_PROJECTS,
                  })}
                >
                  {UI.MY_PROJECTS}
                </div>
              </div>
              <div
                onClick={() => onSelectNavOption(URL.COMPANY_PROJECTS)}
                className={classNames(MENU_ITEM_STYLE)}
              >
                <div
                  to={URL.COMPANY_PROJECTS}
                  className={classNames(KEBAB_LINK, {
                    [ACTIVE_KEBAB_LINK]: activeUrl === URL.COMPANY_PROJECTS,
                  })}
                >
                  {UI.COMPANY_PROJECTS}
                </div>
              </div>
            </div>
          ) : null}

          {loggedInUserRole === LOGIN_TYPE.CANDIDATE ? (
            <div>
              <div className="px-3 py-2 w-100 text-primary">
                {getLoginDetailFromSession()?.name}
              </div>

              <hr className="mt-1 mb-1" />
              {isMobileDevice && (
                <div>
                  <div
                    onClick={() => onSelectNavOption(URL.FIND_JOBS)}
                    className={classNames(MENU_ITEM_STYLE)}
                  >
                    <div
                      to={URL.FIND_JOBS}
                      className={classNames(KEBAB_LINK, {
                        [ACTIVE_KEBAB_LINK]: activeUrl === URL.FIND_JOBS,
                      })}
                    >
                      {UI.FIND_JOBS}
                    </div>
                  </div>
                  <hr className="mt-1 mb-1" />
                </div>
              )}

              <div
                onClick={() => onSelectNavOption(URL.CANDIDATE_PROFILE)}
                className={classNames(MENU_ITEM_STYLE)}
              >
                <div
                  to={URL.CANDIDATE_PROFILE}
                  className={classNames(KEBAB_LINK, {
                    [ACTIVE_KEBAB_LINK]: activeUrl === URL.CANDIDATE_PROFILE,
                  })}
                >
                  {UI.PROFILE}
                </div>
              </div>
              <div
                onClick={() => onSelectNavOption(URL.MY_JOBS)}
                className={classNames(MENU_ITEM_STYLE)}
              >
                <div
                  to={URL.MY_JOBS}
                  className={classNames(KEBAB_LINK, {
                    [ACTIVE_KEBAB_LINK]: activeUrl === URL.MY_JOBS,
                  })}
                  onClick={() => GAEventsTracker(UI.MY_JOBS)}
                >
                  {UI.MY_JOBS}
                </div>
              </div>
              <div
                onClick={() => onSelectNavOption(URL.My_Profile_Activity)}
                className={classNames(MENU_ITEM_STYLE)}
              >
                <div
                  to={URL.My_Profile_Activity}
                  className={classNames(KEBAB_LINK, {
                    [ACTIVE_KEBAB_LINK]: activeUrl === URL.My_Profile_Activity,
                  })}
                  onClick={() => GAEventsTracker(UI.MY_PROFILE_ACTIVITY)}
                >
                  {UI.MY_PROFILE_ACTIVITY}
                </div>
              </div>
              <div
                onClick={() => onSelectNavOption(URL.ASSESSMENT)}
                className={classNames(MENU_ITEM_STYLE)}
              >
                <div
                  to={URL.ASSESSMENT}
                  className={classNames(KEBAB_LINK, {
                    [ACTIVE_KEBAB_LINK]: activeUrl === URL.ASSESSMENT,
                  })}
                  onClick={() => GAEventsTracker(UI.ASSESSMENT)}
                >
                  {UI.ASSESSMENT}
                </div>
              </div>
              <div
                onClick={() => onSelectNavOption(URL.MOCK_INTERVIEW)}
                className={classNames(MENU_ITEM_STYLE)}
              >
                <div
                  to={URL.MOCK_INTERVIEW}
                  className={classNames(KEBAB_LINK, {
                    [ACTIVE_KEBAB_LINK]: activeUrl === URL.MOCK_INTERVIEW,
                  })}
                >
                  {UI.MOCK_INTERVIEW}
                </div>
              </div>
            </div>
          ) : null}
          {windowWidth < SMALL_MOBILE_SCREEN_WIDTH && (
            <div>
              <div
                onClick={() => onSelectNavOption(URL.NOTIFICATIONS)}
                className={classNames(MENU_ITEM_STYLE)}
              >
                <div
                  to={URL.NOTIFICATIONS}
                  className={classNames(KEBAB_LINK, {
                    [ACTIVE_KEBAB_LINK]: activeUrl === URL.NOTIFICATIONS,
                  })}
                >
                  <div className="d-flex align-items-center">
                    <div>{UI.NOTIFICATIONS}</div>
                    {notificationCount > 0 &&
                      windowWidth < SMALL_MOBILE_SCREEN_WIDTH && (
                        <div
                          style={{
                            borderRadius: '50%',
                            backgroundColor: 'red',
                            color: 'white',
                            width: '20px',
                            height: '20px',
                            textAlign: 'center',
                          }}
                          className="ms-1"
                        >
                          {notificationCount}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {loggedInUserRole === LOGIN_TYPE.UNIVERSITY ||
            (loggedInUserRole === LOGIN_TYPE.ADMIN && (
              <div className="px-3 py-2 w-100 text-primary">
                {getLoginDetailFromSession()?.name}
              </div>
            ))}
          {loggedInUserRole ? (
            <div>
              {/* {(loggedInUserRole === LOGIN_TYPE.EMPLOYER ||
                loggedInUserRole === LOGIN_TYPE.CANDIDATE) && (
                <div
                  onClick={() => onSelectNavOption(URL.OFFERS)}
                  className={classNames(MENU_ITEM_STYLE)}
                >
                  <div
                    to={URL.OFFERS}
                    className={classNames(KEBAB_LINK, {
                      [ACTIVE_KEBAB_LINK]: activeUrl === URL.OFFERS,
                    })}
                    onClick={() => GAEventsTracker(UI.OFFERS)}
                  >
                    {UI.OFFERS}
                  </div>
                </div>
              )} */}
              <div
                onClick={() => onSelectNavOption(URL.SETTING)}
                className={classNames(MENU_ITEM_STYLE)}
              >
                <div
                  to={URL.SETTING}
                  className={classNames(KEBAB_LINK, {
                    [ACTIVE_KEBAB_LINK]: activeUrl === URL.SETTING,
                  })}
                >
                  {UI.SETTING}
                </div>
              </div>

              <hr className="mt-1 mb-1" />
              <div
                onClick={handleLogout}
                className={classNames(MENU_ITEM_STYLE)}
              >
                <div className="px-3 py-1 w-100 color-F25C05 cursor-pointer">
                  {UI.LOGOUT}
                </div>
              </div>
            </div>
          ) : (
            <div>
              {isMobileDevice && (
                <Box>
                  <div
                    onClick={() => onSelectNavOption(URL.FIND_JOBS)}
                    className={classNames(MENU_ITEM_STYLE)}
                  >
                    <div
                      to={URL.FIND_JOBS}
                      className={classNames(KEBAB_LINK, {
                        [ACTIVE_KEBAB_LINK]: activeUrl === URL.FIND_JOBS,
                      })}
                    >
                      {UI.FIND_JOBS}
                    </div>
                  </div>
                  <div
                    onClick={() => onSelectNavOption(URL.FIND_CANDIDATES)}
                    className={classNames(MENU_ITEM_STYLE)}
                  >
                    <div
                      to={URL.FIND_CANDIDATES}
                      className={classNames(KEBAB_LINK, {
                        [ACTIVE_KEBAB_LINK]: activeUrl === URL.FIND_CANDIDATES,
                      })}
                    >
                      {UI.FIND_TALENT}
                    </div>
                  </div>
                  <hr className="mt-1 mb-1" />
                </Box>
              )}
              <div
                onClick={() => onSelectNavOption(URL.LOGIN)}
                className={classNames(MENU_ITEM_STYLE)}
              >
                <div
                  to={URL.LOGIN}
                  className={classNames(KEBAB_LINK, {
                    [ACTIVE_KEBAB_LINK]: activeUrl === URL.LOGIN,
                  })}
                >
                  {UI.LOGIN}
                </div>
              </div>
              <div
                onClick={() => onSelectNavOption(URL.REGISTER)}
                className={classNames(MENU_ITEM_STYLE)}
              >
                <div
                  to={URL.REGISTER}
                  className={classNames(KEBAB_LINK, {
                    [ACTIVE_KEBAB_LINK]: activeUrl === URL.REGISTER,
                  })}
                >
                  {UI.REGISTER}
                </div>
              </div>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    ),
    [
      shouldOpen,
      isMobileDevice,
      activeUrl,
      loggedInUserRole,
      hasUpdateJobPermission,
      project,
    ]
  );

  // Navbar Render for Mobile View

  const renderMobileView = useMemo(
    () => (
      <>
        <div className="d-flex justify-content-end align-items-center">
          <IconButton onClick={() => setShouldOpen(true)}>
            <div className={`${styles.groupedRoutes} px-2`}>
              <div style={{ position: 'relative' }}>
                <MenuRoundedIcon fontSize="medium" />
              </div>
              {notificationCount > 0 &&
                windowWidth < SMALL_MOBILE_SCREEN_WIDTH && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      zIndex: 1,
                      display: 'inline-flex',
                      flex: '0 0 0%',
                    }}
                  >
                    <div className={classNames(styles.box)} />
                  </div>
                )}
            </div>
          </IconButton>
        </div>
        <div
          className={`d-flex ${
            windowWidth < 480 ? 'flex-column' : 'flex-row'
          } align-items-center`}
        >
          <div>
            <Link
              to={currentHomeUrl}
              className={classNames(`${styles.logo} color-1D8FF2`, {
                'me-4': !loggedInUserRole,
              })}
            >
              <img
                src={IMAGE_URL.ALIGNMYCAREER}
                alt={UI.ALT_ALIGNMYCAREER}
                className={`${isMobileDevice ? 'logo_mobile' : 'website_logo'}`}
                width="200px"
                loading="lazy"
                title={UI.ALT_ALIGNMYCAREER}
              />
            </Link>

            {!loggedInUserRole && (
              <>
                <div className={BREAK} />
                <div className={FIND_DIV}>
                  <div className="m-2">
                    <Link
                      to={URL.FIND_JOBS}
                      className={`color-1F2830 ${styles.navLink}  ${
                        activeUrl === URL.FIND_JOBS
                          ? `subtitle-1-bold`
                          : 'subtitle-1'
                      }`}
                    >
                      {UI.FIND_JOBS}
                    </Link>
                    {activeUrl === URL.FIND_JOBS ? (
                      <div className={`background-1D8FF2 ${styles.active}`} />
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="m-2">
                    <Link
                      to={URL.FIND_CANDIDATES}
                      className={`color-1F2830 ${styles.navLink} ${
                        activeUrl === URL.FIND_CANDIDATES
                          ? `subtitle-1-bold ${styles.active}`
                          : 'subtitle-1'
                      }`}
                    >
                      {UI.FIND_TALENT}
                    </Link>
                    {activeUrl === URL.FIND_CANDIDATES ? (
                      <div className={`background-1D8FF2 ${styles.active}`} />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center">
          {loggedInUserRole &&
            (loggedInUserRole === LOGIN_TYPE.CANDIDATE ||
              loggedInUserRole === LOGIN_TYPE.EMPLOYER) && (
              <>
                <span>
                  {loggedInUserRole === LOGIN_TYPE.CANDIDATE && (
                    <AvailablePoints />
                  )}
                </span>
                {windowWidth > SMALL_MOBILE_SCREEN_WIDTH && (
                  <span>
                    <NotificationIcon />
                  </span>
                )}
              </>
            )}
        </div>
      </>
    ),
    [loggedInUserRole, hasUpdateJobPermission, project, windowWidth, renderMenu]
  );

  // Navbar Render for Web View

  const renderWebView = useMemo(
    () => (
      <>
        <div>
          <Link
            to={currentHomeUrl}
            className={`${styles.logo} color-1D8FF2 col-2`}
          >
            <img
              src={IMAGE_URL.ALIGNMYCAREER}
              alt={UI.ALT_ALIGNMYCAREER}
              className={`${isMobileDevice ? 'logo_mobile' : 'website_logo'}`}
              height="100%"
            />
          </Link>
        </div>
        <div className={` my-3 d-flex py-1 justify-content-center`}>
          {loggedInUserRole ? (
            <RenderNavBarButtons
              loggedInUserRole={loggedInUserRole}
              activeUrl={activeUrl}
            />
          ) : (
            <>
              <div className="me-3">
                <Link
                  to={URL.FIND_JOBS}
                  className={`color-1F2830 ${styles.navLink}  ${
                    activeUrl === URL.FIND_JOBS
                      ? `subtitle-1-bold`
                      : 'subtitle-1'
                  }`}
                >
                  {UI.FIND_JOBS}
                </Link>
                {activeUrl === URL.FIND_JOBS ? (
                  <div className={`background-1D8FF2 ${styles.active}`} />
                ) : (
                  ''
                )}
              </div>
              <div className="ms-3">
                <Link
                  to={URL.FIND_CANDIDATES}
                  className={`color-1F2830 ${styles.navLink} ${
                    activeUrl === URL.FIND_CANDIDATES
                      ? `subtitle-1-bold ${styles.active}`
                      : 'subtitle-1'
                  }`}
                >
                  {UI.FIND_TALENT}
                </Link>
                {activeUrl === URL.FIND_CANDIDATES ? (
                  <div className={`background-1D8FF2 ${styles.active}`} />
                ) : (
                  ''
                )}
              </div>
            </>
          )}
        </div>

        <div className="d-flex justify-content-end align-items-center">
          {(loggedInUserRole === LOGIN_TYPE.EMPLOYER || !loggedInUserRole) &&
            !isMobileDevice && (
              <div onClick={onClickAIQuestionForm}>
                <span style={{ color: '#0d6efd', cursor: 'pointer' }}>
                  {UI.TRY_AI_ASSESSOR}
                </span>
              </div>
            )}
          {loggedInUserRole &&
            (loggedInUserRole === LOGIN_TYPE.CANDIDATE ||
              loggedInUserRole === LOGIN_TYPE.EMPLOYER) && (
              <div className="d-flex">
                {loggedInUserRole === LOGIN_TYPE.CANDIDATE && (
                  <AvailablePoints />
                )}
                <NotificationIcon />
              </div>
            )}
          {!!loggedInUserRole &&
            loggedInUserRole === LOGIN_TYPE.EMPLOYER &&
            !isMobileDevice && (
              <IconButton
                onClick={() => {
                  navigate(URL.POST_JOB);
                }}
                disabled={!hasUpdateJobPermission}
              >
                <div
                  className={`${
                    hasUpdateJobPermission
                      ? 'background-1D8FF2'
                      : 'background-D7D7D7'
                  } ${styles.groupedRoutes} px-2`}
                >
                  {hasUpdateJobPermission ? (
                    <PostAddIcon sx={{ color: 'white' }} />
                  ) : (
                    <Lock sx={{ color: 'white' }} />
                  )}

                  <Typography
                    component="span"
                    sx={{ fontSize: '1rem', color: 'white', margin: '0.5rem' }}
                  >
                    {UI.POST_JOB}
                  </Typography>
                </div>
              </IconButton>
            )}
          <IconButton onClick={() => setShouldOpen(true)}>
            <div className={`background-1D8FF2 ${styles.groupedRoutes} px-2`}>
              {loggedInUserRole ? (
                <img
                  width="72px"
                  height="32px"
                  src={ICON_OTHER_ROUTES}
                  alt={UI.ALT_OTHER_PAGES}
                />
              ) : (
                <Typography
                  component="span"
                  sx={{ fontSize: '1rem', color: 'white', margin: '0.5rem' }}
                >
                  {UI.LOGIN_REGISTER}
                </Typography>
              )}
            </div>
          </IconButton>
        </div>
      </>
    ),
    [
      loggedInUserRole,
      currentHomeUrl,
      hasUpdateJobPermission,
      project,
      activeUrl,
      renderMenu,
    ]
  );

  const handleClose = () => {
    setShouldShowDialog(false);
  };

  return (
    <>
      <nav
        className={`${styles.header} background-ffffff ${
          loggedInUserRole !== LOGIN_TYPE.ADMIN && 'mb-2'
        }`}
        style={{ position: 'sticky', top: 0, zIndex: 1000 }}
      >
        {isMobileDevice ? (
          <div
            className={`container-fluid  d-flex align-items-center justify-content-between
           ${!loggedInUserRole && MAIN_DIV} ${isSmallDevice && 'p-0'}`}
          >
            {renderMobileView}
          </div>
        ) : (
          <div
            className={`
           ${
             windowWidth > 1200 ? 'container' : 'container-fluid'
           } px-3 d-flex align-items-center justify-content-between
           ${!loggedInUserRole && MAIN_DIV}`}
          >
            {renderWebView}
          </div>
        )}
      </nav>
      {loggedInUserRole === LOGIN_TYPE.EMPLOYER && (
        <div
          className={`${
            windowWidth > 1200 ? 'container' : 'container-fluid'
          }  `}
        >
          <div className="row">
            <div className="d-flex justify-content-end">
              <div
                className={`${
                  isSmallDevice
                    ? 'justify-content-end flex-column'
                    : isMobileDevice
                    ? 'w-100 justify-content-end'
                    : 'justify-content-between'
                } d-flex `}
              >
                <Box
                  display="flex"
                  className="m-1 centered "
                  alignItems="center"
                  justifyContent={isSmallDevice ? 'flex-end' : 'space-between'}
                >
                  {project && !isApprovalPending && (
                    <div
                      className="p-2 background-F3F3F3 me-2"
                      style={{ borderRadius: 10, cursor: 'pointer' }}
                      onClick={() => {
                        navigate(URL.PROJECT_DETAILS);
                      }}
                    >
                      <span className="me-1  subtitle-1-bold color-1F2830 ">
                        {UI.PROJECT_NAME}:
                      </span>
                      <span className="color-1F2830 ">{project}</span>
                    </div>
                  )}
                </Box>

                <Box
                  display="flex"
                  className="m-1  centered"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <AvailablePoints />
                </Box>
              </div>
            </div>
          </div>
        </div>
      )}
      {shouldShowDialog && (
        <Suspense fallback={<Loader />}>
          <AIQuestionForm
            shouldOpenDialog={shouldShowDialog}
            handleClose={handleClose}
          />
        </Suspense>
      )}
      {renderMenu}
    </>
  );
}
NavbarComponent.propTypes = {
  setLoggedInUserRole: PropTypes.func,
  loggedInUserRole: PropTypes.number,
};

NavbarComponent.defaultProps = {
  setLoggedInUserRole: noop,
  loggedInUserRole: undefined,
};

RenderNavBarButtons.propTypes = {
  loggedInUserRole: PropTypes.number,
  activeUrl: PropTypes.string,
};

RenderNavBarButtons.defaultProps = {
  loggedInUserRole: undefined,
  activeUrl: '',
};
export default NavbarComponent;
