const URL = {
  HOME: '/',
  JOB_SEEKER: '/job-seeker',
  RECRUITERS: '/recruiters',
  FOR_RECRUITERS: '/for-recruiters',
  FIND_JOBS: '/find-jobs',
  MOCK_INTERVIEW: '/mockinterview',
  PRICING_DASHBOARD: '/pricing-dashboard',
  FAQS: '/faqs',
  SEO_FIND_JOBS: '/find-jobs/:skillsAndLocations',
  FIND_JOBS_SEARCHES: '/find-jobs/searches',
  FIND_CANDIDATES: '/find-candidates',
  SEO_FIND_CANDIDATES: '/find-candidates/:skillsAndLocations',
  FIND_CANDIDATES_SEARCHES: '/find-candidates/searches',
  POST_JOB: '/postjob',
  CANDIDATE_PROFILE: '/candidate-profile-form',
  // FOR_EMPLOYER: '/company-profile',
  POSTED_JOBS: '/postedjobs',
  JOB_STATUS: '/job-status',
  ASSESSED_CANDIDATE: '/assessed-candidate',
  AI_ASSESSED_CANDIDATE: 'ai/assessed-candidate',
  EMPLOYER_PROFILE: '/company-profile',
  EMPLOYER_ASSESSMENTS: '/recuriter/assessments',
  AI_ASSESSMENT: '/recuriter/aiassessments',
  EMPLOYER_EDIT_ASSESSMENT: `/recuriter/edit-Assessment/`,
  AI_EMPLOYER_EDIT_ASSESSMENT: `/ai/recuriter/edit-Assessment`,
  AI_EMPLOYER_CREATE_ASSESSMENT: `/ai/recuriter/create-assessment`,
  EMPLOYER_CREATE_ASSESSMENT: `/recuriter/create-assessment`,
  COMPANY_EMPLOYEE_PROFILE: '/company-employee',
  EMPLOYER_DETAILS: '/employer-details',
  JOB_DETAILS: '/job-details',
  CANDIDATE_DETAILS: '/candidate-details',
  ASSESSMENT_DETAILS: '/assessment-details',
  EMPLOYER_META: '/employer/',
  JOB_META: '/job/',
  CANDIDATE_META: '/candidate/',
  ASSESSMENT_META: '/assessment/',
  PAYMENT: '/payment',
  MY_CANDIDATES: '/my-candidates',
  MY_JOBS: '/my-jobs',
  My_Profile_Activity: '/my-profile-Activity',
  LOGIN: '/login',
  REGISTER: '/register',
  VERIFY_EMAIL: '/verifyemail',
  CONFIRM_EMAIL: '/confirmemail',
  FORGOT_PASSWORD: '/forgotpassword',
  RESEND_EMAIL: '/resendemail',
  UPDATE_PASSWORD: '/updatepassword',
  CHANGE_PASSWORD: '/changepassword',
  CONFIRM: '/confirm',
  GUIDELINES: '/guidelines',
  ASSESSMENT: '/assessments',
  SETTING: '/setting',
  OFFERS: '/offers',
  ALL_OFFERS: '/all-offers',
  START_ASSESSMENT: '/startassessment',
  INFORMATION: '/information',
  UNIV_STUDENTS: '/univ-students',
  NOTIFICATIONS: '/notifications',
  UNSUBSCRIBE: '/unsubscribe',
  ADMIN_ACTION: '/admin-actions',
  ADMIN_ANALYTICS: '/admin-analytics',
  PROJECT_DETAILS: '/project-details',
  MY_PROJECTS: '/my-projects',
  COMPANY_PROJECTS: '/company-projects',
  ASSESSMENT_REPORT: '/assessmentreport',
  UNSUBSCRIBE_EMAIL: '/unsubscribe',
};
export default URL;

const URL_TYPE = {
  CANDIDATE: 1,
  EMPLOYER: 2,
  COMMON: 3,
  PUBLIC: 4,
  UNIVERSITY: 5,
  ADMIN: 3,
};

export const ROLES_TYPES = {
  CANDIDATE: [URL_TYPE.CANDIDATE],
  EMPLOYER: [URL_TYPE.EMPLOYER],
  UNIVERSITY: [URL_TYPE.UNIVERSITY],
  COMMON: [URL_TYPE.CANDIDATE, URL_TYPE.EMPLOYER, URL_TYPE.UNIVERSITY],
  PUBLIC: [URL_TYPE.PUBLIC],
  ADMIN: [URL_TYPE.ADMIN],
};
