import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { useForm } from 'react-hook-form';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  Typography,
  TextField,
  Box,
  Link,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import URL from '../../../constants/urls';
import ErrorSnackBar from '../../../components/snackBar/ErrorSnackBar';
import STATUS_CODE from '../../../constants/statusCode';
import STORAGE_KEY from '../../../constants/storageKey';
import {
  getLoginDetailFromSession,
  setLoginDetailInSession,
  setPlanDetails,
} from '../../../helpers/sessionDetails';
import { getRequest, postRequest } from '../../../services';
import REGEX_PATTERN from '../../../constants/regex';
import ERROR_MESSAGE from '../../../constants/errorMsgs';
import { API_URL, getPricePlanDetails } from '../../../constants/apiUrls';
import { getDefaultUrlBasedOnRoleAndState } from '../../../helpers/getRedirectUrl';
import UI from '../../../constants/ui';
import { EMPTY_ARRAY, REDIRECT_TIME_OUT } from '../../../constants';
import Dialog from '../../../components/dialog';
import useGAEventsTracker from '../../../hooks/useGAEventsTracker';
import GA from '../../../constants/analytics.constants';
import {
  getAvailablePointsUser,
  getNotificationCountUser,
} from '../../../../redux/apiCalls';
import {
  setAvailablePointsZero,
  setRequestsComplete,
  setRequestsInComplete,
  setRerenderpermissions,
  setHideLoginDialog,
  setPassword,
  setUserName,
} from '../../../../redux/userSlice';
import LOGIN_TYPE from '../../../constants/loginType';
import { showSnackBar } from '../../../../redux/snackBarSlice';
import {
  COMMON_LOGIN_STATE,
  EMPLOYER_LOGIN_ESTATE,
} from '../../../constants/loginStates.constants';
import { ismallMobileDevice } from '../../../hooks/useMobileDevice';
import CONTEXT_TYPE from '../../loginDialog/loginDialog.constants';

const GAEventsTracker = useGAEventsTracker(GA.EVENT_BUTTON.LOGIN);

export function requestDTO(formData, username, base64Encode) {
  return { ...formData, username, password: base64Encode };
}

function Login(props) {
  const {
    setShowNavBar,
    setLoggedInUserRole,
    onDialogClose,
    setOtpTitle,
    setContext,
  } = props;
  const isLoginDialogOpen = useSelector((state) => state.user.showLoginDialog);
  const [isApprovalDialogOpen, setIsApprovalDialogOpen] = useState(false);
  const [contentForApprovalDialog, setContentForApprovalDialog] = useState('');
  const [titleForApprovalDialog, setTitleForApprovalDialog] = useState('');

  const [isApprovalDialogLoading, setIsApprovalDialogLoading] = useState();

  const [isRejectedDialogOpen, setIsRejectedDialogOpen] = useState(false);
  const [contentForRejectedDialog, setContentForRejectedDialog] = useState('');
  const [isRejectedDialogLoading, setIsRejectedDialogLoading] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isSpotOnMockInterview = useSelector(
    (state) => state.user.shouldSpotOnMockInterviewPage
  );
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [opensnackbar, setSnackbarOpen] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const isSmallDevice = ismallMobileDevice();
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen({ setopen: false });
  };

  useEffect(() => {
    setShowNavBar(true);
  }, EMPTY_ARRAY);

  function checkProjectProperty(resolve) {
    const intervalId = setInterval(() => {
      const loginDetail = getLoginDetailFromSession();
      if (loginDetail && loginDetail.project) {
        clearInterval(intervalId);
        resolve(dispatch(setRequestsComplete()));
        dispatch(setRerenderpermissions());
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }

  const getPlanDetails = (planid) => {
    getRequest(getPricePlanDetails(planid))
      .then((res) => {
        setPlanDetails(res);
      })
      .catch((err) => {
        setSnackbarOpen({
          setopen: true,
          message: err.msg,
          severity: 'error',
        });
      });
  };

  const afterLogin = async () => {
    const userDetail = getLoginDetailFromSession();
    setLoggedInUserRole(userDetail.role);
    GAEventsTracker(userDetail.name + GA.LOGIN);

    let navigateUrl = searchParams.get('redirectTo');
    let redirectUrl = searchParams.get('redirectTo');
    if (userDetail.role === LOGIN_TYPE.EMPLOYER) {
      const totalprojects = userDetail?.projects?.length;
      const selectedproject = JSON.parse(
        localStorage.getItem(STORAGE_KEY.SELECTED_PROJECT_ID)
      );
      const isUserPartOfProject =
        totalprojects > 0 && userDetail?.projects?.includes(selectedproject);
      let selectedprojectCallUnsuccessful = true;
      if (isUserPartOfProject) {
        await new Promise((resolve) => {
          getRequest(`${API_URL.PROJECT_LOAD}/${selectedproject}`)
            .then(() => {
              navigateUrl =
                navigateUrl ||
                getDefaultUrlBasedOnRoleAndState(
                  userDetail.role,
                  userDetail.state,
                  isSpotOnMockInterview
                );
              redirectUrl = null;
              selectedprojectCallUnsuccessful = false;
              getRequest(API_URL.CREDENTIAL)
                .then((resp) => {
                  setLoginDetailInSession(resp);
                  getAvailablePointsUser(dispatch);
                  getNotificationCountUser(dispatch);
                  getPlanDetails(resp?.planid);
                })
                .finally(() => {
                  if (selectedprojectCallUnsuccessful) resolve();
                  else {
                    const clearCheck = checkProjectProperty(resolve);
                    setTimeout(() => {
                      clearCheck();
                    }, 10000);
                  }
                });
            })
            .catch(() => resolve());
        });
      }
      if (selectedprojectCallUnsuccessful) {
        if (totalprojects === undefined || totalprojects === 0) {
          navigateUrl = URL.COMPANY_PROJECTS;
        } else if (totalprojects === 1) {
          // getRequest to select the particular project
          await new Promise((resolve) => {
            getRequest(`${API_URL.PROJECT_LOAD}/${userDetail?.projects[0]}`)
              .then(() => {
                localStorage.setItem(
                  STORAGE_KEY.SELECTED_PROJECT_ID,
                  JSON.stringify(userDetail?.projects[0])
                );

                navigateUrl =
                  navigateUrl ||
                  getDefaultUrlBasedOnRoleAndState(
                    userDetail.role,
                    userDetail.state,
                    isSpotOnMockInterview
                  );

                redirectUrl = null;
                getRequest(API_URL.CREDENTIAL)
                  .then((resp) => {
                    setLoginDetailInSession(resp);
                    getAvailablePointsUser(dispatch);
                    getNotificationCountUser(dispatch);
                    getPlanDetails(resp?.planid);
                  })
                  .finally(() => {
                    const clearCheck = checkProjectProperty(resolve);
                    setTimeout(() => {
                      clearCheck();
                    }, 10000);
                  });
              })
              .catch((error) => {
                setSnackbarOpen({
                  setopen: true,
                  message: error.msg,
                  severity: 'error',
                });
                resolve();
              });
          });
        } else {
          navigateUrl = URL.MY_PROJECTS;
        }
      }
    } else {
      navigateUrl =
        navigateUrl ||
        getDefaultUrlBasedOnRoleAndState(
          userDetail.role,
          userDetail.state,
          isSpotOnMockInterview
        );
    }
    if (userDetail.role === LOGIN_TYPE.CANDIDATE) {
      getAvailablePointsUser(dispatch);
      getNotificationCountUser(dispatch);
      dispatch(setRequestsComplete());
      dispatch(setRerenderpermissions());
    }

    if (isLoginDialogOpen) {
      onDialogClose();
      window.location.reload();
    }

    if (redirectUrl && userDetail.role === LOGIN_TYPE.EMPLOYER)
      navigate({
        pathname: navigateUrl,
        search: `redirectTo=${encodeURIComponent(redirectUrl)}`,
      });
    else navigate(navigateUrl, { replace: true });
  };

  const handleLogin = () => {
    setIsLoading(true);
    getRequest(API_URL.CREDENTIAL)
      .then((resp) => {
        setLoginDetailInSession(resp);
        const loggedInUserRole = resp.role;
        if (loggedInUserRole === LOGIN_TYPE.CANDIDATE) {
          getRequest(API_URL.LAST_ASSESSMENT)
            .then((response) => {
              if (response?.showmsg) {
                dispatch(
                  showSnackBar({
                    setopen: true,
                    message: response?.msg,
                    severity: 'info',
                    duration: 10000,
                  })
                );
              }
            })
            .catch((error) => {
              setSnackbarOpen({
                setopen: true,
                message: error.msg,
                severity: 'error',
              });
            });
          afterLogin();
        } else if (loggedInUserRole === LOGIN_TYPE.EMPLOYER) {
          const userDetail = getLoginDetailFromSession();
          if (
            userDetail?.estate === EMPLOYER_LOGIN_ESTATE.APPROVAL_PENDING ||
            userDetail?.state === COMMON_LOGIN_STATE.INACTIVE
          ) {
            if (userDetail?.estate === EMPLOYER_LOGIN_ESTATE.APPROVAL_PENDING) {
              setTitleForApprovalDialog(UI.ACCOUNT_APPROVAL_PENDING);
              setContentForApprovalDialog(UI.ACCOUNT_APPROVAL_PENDING_MSG);
            } else {
              setTitleForApprovalDialog(UI.ACCOUNT_INACTIVE);
              setContentForApprovalDialog(UI.ACCOUNT_INACTIVE_MSG);
            }
            setIsApprovalDialogLoading(true);
            setIsApprovalDialogOpen(true);
            setIsApprovalDialogLoading(false);
          } else if (userDetail?.estate === EMPLOYER_LOGIN_ESTATE.REJECTED) {
            setIsRejectedDialogLoading(true);
            setContentForRejectedDialog(UI.ACCOUNT_REJECTED_MSG);
            setIsRejectedDialogOpen(true);
            setIsRejectedDialogLoading(false);
          } else {
            // account approved
            afterLogin();
          }
        } else if (
          loggedInUserRole === LOGIN_TYPE.UNIVERSITY ||
          loggedInUserRole === LOGIN_TYPE.ADMIN
        ) {
          afterLogin();
        }
      })
      .finally(() => setIsLoading(false));
  };

  const logoutApi = () => {
    postRequest(API_URL.LOGOUT)
      .then((res) => {
        if (res.code === STATUS_CODE.SUCCESSFULLY_LOGOUT) {
          localStorage.removeItem(STORAGE_KEY.CANDIDATE_DETAILS);
          localStorage.removeItem(STORAGE_KEY.EMPLOYER_DETAILS);
          localStorage.removeItem(STORAGE_KEY.SESSION_DETAILS);
          localStorage.removeItem(STORAGE_KEY.PRICE_PLAN_DETAILS);
          navigate(URL.HOME);
          setLoggedInUserRole();
          dispatch(setRequestsInComplete());
          dispatch(setRerenderpermissions());
          dispatch(setAvailablePointsZero());
          dispatch(setHideLoginDialog());
        }
      })
      .catch(() => {});
  };

  const onClickBtn = (data) => {
    const base64Encode = btoa(data.password);
    const username = data.username.toLowerCase();
    dispatch(setPassword(data.password));
    dispatch(setUserName(username));
    const requestPayload = requestDTO(data, username, base64Encode);
    const requestPayloads = new URLSearchParams(requestPayload);
    const finalPayload = requestPayloads.toString();
    setIsLoading(true);
    axios
      .post(API_URL.LOGIN, finalPayload, {
        withCredentials: true,
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      })
      .then((res) => {
        setSnackbarOpen({
          setopen: true,
          message: res?.data?.msg,
          severity: 'success',
        });

        // dispatch(
        //   showSnackBar({
        //     setopen: true,
        //     message: 'res?.data?.msg',
        //     severity: 'success',
        //     duration: 10000
        //   })
        // );
        if (res.data.code === STATUS_CODE.SUCCESSFULLY_LOGIN) {
          handleLogin();
        }
      })
      .catch((error) => {
        dispatch(
          showSnackBar({
            setopen: true,
            message: error.response?.data?.msg,
            severity: 'error',
            duration: 3000,
          })
        );
        if (
          error.response.data.code ===
          STATUS_CODE.RESEND_EMAIL_OF_DISABLE_ACCOUNTS
        ) {
          setTimeout(() => {
            navigate(`${URL.VERIFY_EMAIL}?email=${data.username}`);
          }, REDIRECT_TIME_OUT);
        } else if (
          error.response.data.code === STATUS_CODE.RESEND_OTP_OF_DISABLE_ACCOUNT
        ) {
          if (!isLoginDialogOpen) {
            setTimeout(() => {
              navigate(`${URL.REGISTER}?otp=true`);
            }, REDIRECT_TIME_OUT);
          }
          if (isLoginDialogOpen) {
            setOtpTitle(true);
            setContext(CONTEXT_TYPE.REGISTER_FORM);
          }
        }
      })
      .finally(() => setIsLoading(false));
  };
  const [values, setValues] = useState({
    password: '',
    showPassword: false,
  });
  const handleChange = (prop) => (event) => {
    const password = event.target.value;
    setValues({ ...values, [prop]: password });
    setValue('password', password);
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleApprovalDialogClose = () => {
    setIsApprovalDialogOpen(false);
    afterLogin();
  };

  const handleRejectedDialogClose = () => {
    setIsRejectedDialogOpen(false);
    logoutApi();
  };

  return (
    <div className={`${isSmallDevice && 'p-3'}`}>
      <Dialog
        isDialogOpen={isApprovalDialogOpen}
        onDialogClose={handleApprovalDialogClose}
        title={titleForApprovalDialog}
        content={contentForApprovalDialog}
        isLoading={isApprovalDialogLoading}
        primaryLabel={UI.OK}
        primaryAction={handleApprovalDialogClose}
      />
      <Dialog
        isDialogOpen={isRejectedDialogOpen}
        onDialogClose={handleRejectedDialogClose}
        title={UI.ACCOUNT_REJECTED}
        content={contentForRejectedDialog}
        isLoading={isRejectedDialogLoading}
        primaryLabel={UI.OK}
        primaryAction={handleRejectedDialogClose}
      />
      <div
        className={`card container ${isSmallDevice ? 'p-3' : 'p-5'} mt-4`}
        style={{
          boxShadow: '0px 0px 3px #48B2FF29',
          backgroundColor: '#fff',
          borderRadius: '8px',
          maxWidth: 350,
        }}
      >
        <form onSubmit={handleSubmit(onClickBtn)} autoComplete="on">
          <div className="headline-5 mb-3">{UI.LOGIN}</div>
          <div className="my-2">{UI.EMAIL}</div>
          <TextField
            {...register('username', {
              required: ERROR_MESSAGE.REQ_ERROR_MSG,
              pattern: {
                value: REGEX_PATTERN.EMAIL_FORMAT,
                message: ERROR_MESSAGE.EMAIL_VALIDATION,
              },
              onChange: (event) => {
                setValue('username', event.target.value.toLowerCase());
              },
            })}
            fullWidth
            type="email"
            id="username"
            variant="outlined"
            size="small"
            placeholder={UI.ENTER_EMAIL}
            name="username"
            autoComplete="username"
          />

          {errors.username && (
            <Box>
              <span className="mandatory">{errors.username.message}</span>
            </Box>
          )}
          <div className="my-2">{UI.PASSWORD}</div>
          <OutlinedInput
            {...register('password', {
              required: ERROR_MESSAGE.REQ_ERROR_MSG,
            })}
            fullWidth
            id="password"
            variant="outlined"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            placeholder={UI.ENTER_PASSWORD}
            name="password"
            size="small"
            autoComplete="current-password"
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {errors.password && (
            <Box>
              <span className="mandatory">{errors.password.message}</span>
            </Box>
          )}
          <Typography sx={{ mb: 2 }}>
            <Link
              // sx={{ color: 'gray' }}
              as={NavLink}
              to={URL.FORGOT_PASSWORD}
              underline="none"
            >
              <span className="subtitle-2 color-1D8FF2">
                {UI.FORGOT_PASSWORD}
              </span>
            </Link>
          </Typography>

          <Button
            fullWidth
            size="large"
            variant="contained"
            type="submit"
            sx={{ mb: 2 }}
            disabled={isLoading}
            startIcon={
              isLoading && <CircularProgress size="1rem" color="inherit" />
            }
          >
            {UI.LOGIN}
          </Button>
        </form>
        {/* <Box component="div" textAlign="center">
                Or
              </Box>
              <Box component="div">
                <Button
                  fullWidth
                  startIcon={<GoogleIcon />}
                  size="large"
                  variant="outlined"
                  type="submit"
                  sx={{ color: 'gray', mt: 2,  }}
                >
                  Login with Google
                </Button>
              </Box>

              <Button
                fullWidth
                startIcon={<LinkedInIcon />}
                size="large"
                variant="outlined"
                type="submit"
                sx={{ color: 'gray', mt: 2,  }}
              >
                Login with LinkedIn
              </Button> */}
      </div>

      {isLoginDialogOpen ? (
        <Typography sx={{ color: 'gray', mt: 1, textAlign: 'center' }}>
          {UI.NEW_TO_COMPANY_IN}&nbsp;
          <span
            style={{ color: '#1976d2', cursor: 'pointer' }}
            onClick={() => setContext(CONTEXT_TYPE.REGISTER_FORM)}
          >
            {UI.REGISTER_FOR_FREE}
          </span>
        </Typography>
      ) : (
        <Typography sx={{ color: 'gray', mx: 4, mt: 1, textAlign: 'center' }}>
          {UI.NEW_TO_COMPANY_IN}&nbsp;
          <Link
            sx={{ color: '#1976d2' }}
            as={NavLink}
            to={URL.REGISTER}
            underline="none"
          >
            {UI.REGISTER_FOR_FREE}
          </Link>
        </Typography>
      )}
      <ErrorSnackBar opensnackbar={opensnackbar} handleClose={handleClose} />
    </div>
  );
}

Login.propTypes = {
  setShowNavBar: PropTypes.func,
  setLoggedInUserRole: PropTypes.func,
  onDialogClose: PropTypes.func,
  setShowRegisterDialog: PropTypes.func,
  setContext: PropTypes.func,
  setOtpTitle: PropTypes.func,
};

Login.defaultProps = {
  setShowNavBar: noop,
  setLoggedInUserRole: noop,
  onDialogClose: noop,
  setShowRegisterDialog: noop,
  setContext: noop,
  setOtpTitle: noop,
};

export default Login;
