const STORAGE_KEY = {
  PAGE_TYPE: 'pageType',
  BOOTSTRAP: 'bootstrap',
  CANDIDATE_DETAILS: 'candidateDetails',
  EMPLOYER_DETAILS: 'employerDetails',
  SESSION_DETAILS: 'sessionDetails',
  COOKIES_POLICY_BANNER: 'cookiePolicyBanner',
  CANDIDATE_SEARCH_REQUEST: 'candidatesSearchRequests',
  JOB_SEARCH_REQUEST: 'findJobsSearchRequests',
  SHOW_PROCTORING_WARNINGS: 'showwarning',
  ERROR_FALLBACK_REDIRECT: 'errorredirect',
  SELECTED_PROJECT_ID: 'selectedproject',
  PRICE_PLAN_DETAILS: 'plandetails',
  LAST_REVIEW_SEARCH: 'lastReviewSearch',
  IS_CLCIKED_ON_MOCK_INTERVIEW: 'isClickedOnMockInterview',
};

export default STORAGE_KEY;
