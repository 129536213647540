/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userInfo: {
      availablePoints: {
        freepoints: {
          point: 0,
        },
        purchasedpoints: {
          point: 0,
        },
      },
      transactionHistory: [],
      fixedTransactionHistory: [],
      notificationsCount: 0,
    },
    pending: null,
    error: false,
    role: '',
    areRequestsComplete: false,
    reRenderPermissionsCode: 0,
    showLoginDialog: false,
    username: '',
    password: '',
    buttonActionWhilePayment: 0, // need to change the name according to the action
    assessmentList: [],
    aiPreRequisitesAssessments: [],
    chatHistory: [],
    configureData: '',
    isConfigured: false,
    configPayLoad: '',
    shouldOpenAIAudioDialog: false,
    shouldSpotOnMockInterviewPage: false,
  },

  reducers: {
    setshouldSpotOnMockInterviewPage: (state, action) => {
      state.shouldSpotOnMockInterviewPage = action.payload;
    },
    setShouldOpenAIAudioDialog: (state, action) => {
      state.shouldOpenAIAudioDialog = action.payload;
    },
    setConfigPayload: (state, action) => {
      state.configPayLoad = action.payload;
    },
    setConfigureData: (state, action) => {
      state.configureData = action.payload;
    },
    setIsConfigured: (state, action) => {
      state.isConfigured = action.payload;
    },
    setAIPreRequisitesAssessments: (state, action) => {
      state.aiPreRequisitesAssessments = action.payload;
    },
    setAssessmentList: (state, action) => {
      state.assessmentList = action.payload;
    },
    setChatHistory: (state, action) => {
      const { question, answer } = action.payload;
      state.chatHistory.push({ question, answer });
    },
    getUserStart: (state) => {
      state.pending = true;
    },
    setShowLoginDialog: (state) => {
      state.showLoginDialog = true;
    },
    setButtonActionWhilePayment: (state, action) => {
      state.buttonActionWhilePayment = action.payload;
    },

    setUserRole: (state, action) => {
      state.role = action.payload;
    },

    setHideLoginDialog: (state) => {
      state.showLoginDialog = false;
    },
    setUserName: (state, action) => {
      state.username = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    getUserSuccess: (state, action) => {
      state.pending = false;
      state.userInfo.availablePoints = action.payload;
    },
    setAvailablePointsZero: (state) => {
      state.userInfo.availablePoints = 0;
    },
    getUserFailure: (state) => {
      state.pending = false;
      state.error = true;
    },
    getNotificationCountStart: (state) => {
      state.pending = true;
    },
    getNotificationCountSuccess: (state, action) => {
      state.pending = false;
      state.userInfo.notificationsCount = action.payload;
    },
    getNotificationCountFailure: (state) => {
      state.pending = false;
      state.error = true;
    },
    getTransactionHistoryStart: (state) => {
      state.pending = true;
    },
    setNotificationCountToZero: (state) => {
      state.userInfo.notificationsCount = 0;
    },
    setRequestsComplete: (state) => {
      state.areRequestsComplete = true;
    },
    setRequestsInComplete: (state) => {
      state.areRequestsComplete = false;
    },
    setRerenderpermissions: (state) => {
      state.reRenderPermissionsCode += 1;
    },
    getTransactionHistorySuccess: (state, action) => {
      state.pending = false;
      state.userInfo.fixedTransactionHistory = action.payload;
    },
    filterTransactionHistory: (state, action) => {
      state.userInfo.transactionHistory = action.payload;
    },
    getTransactionHistoryFailure: (state) => {
      state.pending = false;
      state.error = true;
    },
  },
});

export const {
  setshouldSpotOnMockInterviewPage,
  setShouldOpenAIAudioDialog,
  setConfigPayload,
  setIsConfigured,
  setConfigureData,
  setAIPreRequisitesAssessments,
  setAssessmentList,
  getUserFailure,
  getUserStart,
  getUserSuccess,
  getNotificationCountStart,
  getNotificationCountSuccess,
  setShowLoginDialog,
  setHideLoginDialog,
  setUserRole,
  setPassword,
  setUserName,
  setButtonActionWhilePayment,
  getNotificationCountFailure,
  getTransactionHistorySuccess,
  getTransactionHistoryFailure,
  getTransactionHistoryStart,
  filterTransactionHistory,
  setNotificationCountToZero,
  setRequestsComplete,
  setRequestsInComplete,
  setAvailablePointsZero,
  setRerenderpermissions,
  setChatHistory,
} = userSlice.actions;

export default userSlice.reducer;
