const UI = {
  //  Filter
  LOADING: 'Loading',
  METHOD: 'Method',
  MORE: 'more',
  SHOW: 'Show',
  RESET: 'Reset',
  FILTER: 'Filter',
  FILTER_APPLIED: '(Filters applied)',
  SHOWING_RESULT_FOR: 'Showing result for',
  SHOWING_RESULT_FOR_MOBILE: 'Results for',
  JOBS: 'Jobs',
  OTP: 'OTP',
  RATINGAS: 'Ratings',
  WRITE_REVIEW: 'Write Review',
  UPDATE_REVIEW: 'Update Review',
  SEARCH_COPANY_REVIEWS: 'Search Company',
  REVIEWS: 'Reviews',
  INTERVIEW_FEEDBACK: 'Interview Feedback',
  COMPANY_REVIEWS: 'Company Reviews',
  REMARKS: 'Remarks',
  COOKIES_POLICY: 'Cookie policy',
  EMPLOYER_PROFILE: 'Employer profile',
  DEPARTMENT: 'Department',
  COOKIES_MSG:
    'We use cookies to ensure that you get the best experience on our website. By continuing to use this site, you give your consent to our',
  DEGREE: 'Degree',
  APPLICABLE_WHEN_PROFILE_IS_UPDATED: 'Applicable when profile is updated',
  VALIDITY: 'Validity',
  NEXT_ATTEMPT: 'Next Attempt',
  MATCHING: 'Matches',
  SEARCH_ON: 'Searched',
  RECENT_SEARCHES: 'Recent Searches',
  CONTACT_PERSON: 'Contact person',
  APPLIED_JOBS: 'Applied Jobs',
  AUTO_APPLIED_BY_EMPLOYER: 'Auto Applied By Employer',
  CANDIDATES_FOR_JOB: 'Candidates for Job',
  ASSESSMENT_GIVEN_BY_CANDIDATE: 'Assessed Candidates',
  MARKED: 'Marked ',
  PAGE_NOT_FOUND:
    'This space is blank right now, but every story began with a blank page.', // TBD - 'Page not Found...',
  PAGE_NOT_FOUND1:
    'Nothing here yet, but every painting was once a blank canvas', // TBD - there can be multiple such options to be shown to the user...'Page not Found...',
  //  Guidelines
  START: 'Start',
  GENERAL_INSTRUCTION: 'General Instructions',
  TERMS_CONDITIONS: 'Terms & Conditions',
  GUIDELINE_INSTRACTION: 'I have read all the instructions and agree to the',
  ASSESSMENT_INSTRUCTION: 'Assessment Instructions',
  AI_INTERVIEW_INSTRUCTION: 'AI Interview Instructions',
  //  Assessment
  NOT_PASSED: 'Not passed',
  NO_RESULT: 'No result',
  ATTEMPTS_HISTORY: 'Attempts History',
  COMPLETED: 'Completed',
  COMPLETE: 'Complete',
  FOUND: 'found',
  AVAILABLE: 'Available',
  ANY: 'Any',
  ANY_STRUCTURED: 'ANY',
  REMANING_TIME: 'Remaining time',
  TIME_SPENT_ON_THIS_QUESTION: 'Time spent on this question',
  READ_TO_INSTRUCTIONS: 'Read instructions',
  END_TEST: 'End Test',
  SHOW_TO_EMPLOYER: 'Showcase this badge', // TBD 'Show to employer'
  YOUR_SKILL_RATING: 'Your current skill level is',
  SUBMIT_SUCCESSFUL: 'Your Assessment has been submitted successfully',
  INTERVIEW_SUBMIT_SUCCESSFUL: 'Your Interview has been submitted successfully',
  YOUR_SCORE_IS_VERY_LESS:
    'You made a good attempt, but a little more effort will help you improve your rating. An improved rating will increase your chances of getting hired. We suggest you study the relevant topics thoroughly and re-assess yourself after 15 days.', // 'Your score is very low and he is not even in the category of Beginner, You have not crossed the cut-off set by us, so your ratings will not be visible to the employers. Please give assessment again after 30 days.'
  ASSESSMENT: 'Assessment',
  ASSESSMENTS: 'Assessments',
  ASSESSMENT_LOWER_CASE: 'assessments',
  AI_ASSESSMENT_VIEW_HEADING: 'AI Assessments associated with Jobs',
  AI_INTERVIEWS: 'AI Interviews',
  INTERVIEW: 'Interview',
  CONFIG: 'Advanced Configuration',
  QUES: '#Ques',
  AI_ASSESSMENT: 'Configure AI Interview',
  TRY_AI_ASSESSOR: 'Try AI Assessor',
  MOCK_INTERVIEW: 'Mock Interview',
  AI_MOCK_INTERVIEW_TITLE: 'AI based Mock Interviews',
  AI_MOCK_INTERVIEW_HELP_TEXT: `Gain the skills and confidence to excel in both technical and non-technical interviews with our AI-driven, skill and role-based mock interviews.`,
  SKILL_BASE: 'Skill Based',
  ROLE_BASE: 'Role Based',
  MOCK_INTERVIEWS: 'Mock Interviews',
  AI_ASSESSMENT_NAVBAR: 'AI Assessments',
  SKILLS: 'Skills',
  SETTING: 'Settings',
  OFFERS: 'My Offers',
  SAVE_SUBMIT: 'Save & Submit',
  SAVE_NEXT: 'Save & Next',
  SEC: 'sec',
  APPROX_TIME: 'Approximate time you should spend on this question',
  SKILL_PLACEHOLDER: 'HTML, CSS, JAVASCRIPT, JAVA, PYTHON, ...',
  AI_SCORE_OUT: 'AI Score out of 100',
  AI_SCORE: 'AI Score',
  AI_ASSESSMENT_NAME_PLACE_HOLDER: 'AI Interview for Job: ',
  PLEASE_SELECT_AN_ANY_OPTION: 'Please select an option.',
  RE_ATTEMPT: 'Re-Attempt',
  NEED_IMPROVEMENT: 'Needs improvement',
  SKILL_RATING: 'Rating',
  ERROR: 'Error',
  START_TEST: ' Start Test',
  START_AI_INTERVIEW_TEST: 'Start AI Interview',
  START_INTERVIEW: 'Start Interview',
  NUMBER_OF_QUESTIONS: 'Question Number', // As the context is showing question # out of total number so Number of Question is incorrect
  QUESTIONS: 'Questions',
  QUESTION: 'Question',
  MCQ_QUESTIONS: 'MCQ Question',
  TOTAL_QUESTION: 'Total Question',
  TOTAL_QUESTIONS: 'Total Questions',
  INTERVIEW_DURATION: 'Interview Duration',
  CODING_QUESTION: 'Coding Question',
  ASSESSMENT_TYPE: 'Assessment Type',
  ViEW_QUESTIONS: 'Questions',
  COMPLEXITY: 'Complexity',
  TECHNOLOGIES: 'Technologies',
  EASY: 'Easy',
  MEDIUM: 'Medium',
  HARD: 'Hard',
  TIME: 'Time',
  MIN: 'min',
  REFRESH: 'Refresh',
  AUTO_REFRESH: 'Auto Refresh in',
  PRICING_MODEL: 'Pricing Model',
  TOTAL_ASSESSMENT_FOUND: 'Total assessments found',
  FOLLOWING: 'Following',
  PRE_REQUISITES_INFO_MSG:
    'Following assessments are mandatory to apply for this job:',
  PRE_REQUISITES_SKILL_INFO_MSG:
    'Following skills are mandatory to apply for this job:',
  PRE_REQUISITES_CERTIFICATE_INFO_MSG:
    'Any one of the following certifications are mandatory to apply for this job:',
  PRE_REQUISITES_AI_INTERVIEW_TEXT:
    'in the following skills, is mandatory to apply for this job.',
  AI_INTERVIEW: 'AI Interview',
  LOGIN_REQD_ASSESSMENT: '* Login is required to take assessments',
  SKILL_TYPE: 'Skill Type',
  AVAILABLE_ASSESSMENT: 'Available Assessment',
  AVAILABLE_INTERVIEW: 'Available Interviews',
  GREAT_START:
    'Great start, but time has run out. You won’t be able to attempt the rest of the assessment this time. Please re-assess yourself after 30 days.', // 'Great start, but your time is up so are not able to answer remaing questions.',
  GENERATE_REPORT: 'Generate Report',
  RETRY_AFTER_30_DAYS: 'Retry after 30 days', // TBD: Need to remove hardcoding
  CLOSE: 'Close',
  YOU_ARE_GOING_TO_SUBMIT_ASSESSMENT:
    'You are going to submit this %s, Once submitted your result will be generated.', // TBD - Context not clear… It requires changes
  ASSESSMENT_CONFIRM: 'Do you want to take the assessment?',
  INTERVIEW_CONFIRM: 'Do you want to take the Interview?',
  DO_YOU_WANT_TO_CONFIRM: 'Do you want to confirm?',
  HOLD_ON: 'Hold on.',
  FLIP_QUESTIONS: 'Flipped questions',
  QUESTIONS_REMAINING: 'questions are remaining.',
  SURE_SUBMIT_ASSESSMENT: 'Do you want to submit this %s?',
  SUBMIT_ASSESSMENT: 'Submit Assessment',
  SUBMIT_INTERVIEW: 'Submit Interview',
  ASSESSMENT_TIMED_OUT: 'Assessment timed out',
  YES: 'Yes',
  NO: 'No',
  END_ASSESSMENT_TITLE: 'End %s',
  SURE_END_ASSESSMENT: 'Do you want to end this %s?',
  SURE_DELETE_ACCOUNT: `Deleting the account will remove all your information present in your system (in the form of cookies, browser storage) as well as all your information present in our database. You won't be able to login with this registered email ID. Do you want to Delete this account?`,
  FLIP_THE_QUESTIONS: 'Flip the Question',
  FLIP_THIS_QUESTIONS: 'Flip this Question',
  SURE_FLIP_QUESTION: 'Do you want to flip this question?',
  //  Summary List
  PAGE: 'Page',
  SHOWING: 'Showing',
  RESULTS: 'Results',
  SORT_BY: 'Sort by',
  OF: 'of',
  RELEVANCE: 'Relevance',
  //  Posted Jobs
  MATCHING_CANDIDATES: 'Matching Candidates',
  APPLIED_CANDIDATES: 'Applicants',
  PUBLISHED: 'Published',
  STATUS: 'Status',
  UPDATED: 'Updated',
  PRE_REQUISITES: 'Pre-requisites',
  JOB_PRE_REQUISITES: 'Job Pre-requisites',
  PRE_REQUISITES_MSG:
    'Allow only those candidates who have passed the following assessments.',
  AI_PRE_REQUISITES_MSG:
    'Allow only those candidates who have given the AI Interview in following skills.',
  PRE_REQUISITES_SKILLS_MSG:
    'Allow only those candidates who possess the following skills.',
  PRE_REQUISITES_ALLOW_STUDENTS:
    'Allow only those candidates who have matching experience and desired location',
  NOTE_PRE_REQUISITES_ALLOW_STUDENTS: `Note: This will allow only the candidates having %s experience to apply for this job posting`,
  OTHER_BOARD_TEXT: `Post this Job to all AlignMyCareer's current and future job board partners`,
  JOB_PARTNERS: 'Job Partners',
  //  Transaction Status
  BANK_TRANSACTION_ID: 'Bank transaction Id',
  GATEWAY_TRANSACTION_ID: 'Gateway transaction Id',
  ORDERID: 'Order ID',
  TRANSACTION_TIME: 'Transaction time',
  AMOUNT: 'Amount',
  MESSAGE: 'Message',
  OK: 'OK',
  //  Buy Credit Points
  BUY_CREDIT: 'Buy Credit Points',
  INPUT: 'Test Input',
  OUTPUT: 'Output',
  EXPECTED_OUTPUT: 'Expected Output',
  PLEASE_ENTER_COMPLIE_CODE: 'Please enter some code to compile',
  PLEASE_ENTER_YOUR_ANSWER: 'Please enter your answer',
  RESET_CODE: 'Reset Code',
  BUY_SUBSCRIPTION: 'Buy Subscription Plan',
  BUY_RENEW_PLAN: 'Renew Plan',
  ENTER_AMOUNT: 'Enter Amount',
  POINTS_WILL_BE_ADDED: 'Equivalent to points',
  APPLY_DISCOUNT_CODE: 'Apply Discount Code',
  PLACEHOLDER_DISCOUNT_CODE: 'Enter discount code',
  PLACEHOLDER_REFERRAL_CODE: 'Enter Referral Code',
  OPTIONAL: '(optional)',
  BUTTON_NEXT: 'Next',
  // Custom Assessment Page
  NEW_ASSESSMENT: 'Create Assessment',
  NEW_AI_ASSESSMENT: 'Create AI Assessment',
  CUSTOM_ASSESSMENTS: 'Custom AI Assessments',
  AI_ASSOCIATED_ASSESSMENT: 'AI Interviews associated with Jobs',
  NO_AI_ASSOCIATED_ASSESSMENT: 'No AI Interviews associated with Jobs',
  // My Offers
  REFER_EMPLOYERS: 'Referral Offers for Employers:',
  REFERRAL_CODE: 'Referral Code',
  PROMOTION_CANDIDATES: 'For Candidates:',
  REFER_TO_CANDIDATES: 'Refer a Candidate:',
  REFER_TO_EMPLOYER: 'Refer an Employer:',
  PROMOTION_EMPLOYER: 'For Employers:',
  REFER_CANDIDATES: 'Referral Offers for Candidates:',
  REFERRAL_LINK: 'Generate Referral Link',
  REFER_SUCCESS: 'Referral Link Copied to clipboard!',
  //  Point Summary
  CREDIT_POINTS: 'Credit Points',
  PROFILE: 'Profile',
  POINTS: 'Points',
  AVAILABLE_POINTS: 'Available Points',
  ACTIVE_PLAN: 'Active Plan',
  BUY_POINTS: 'Buy Points',
  FREE_POINTS: 'Free Points',
  REDEEM_FREE_POINTS: 'Redeem coupon',
  FREE_CODE_PLACEHOLDER: 'Enter coupon code',
  APPLY_BUTTON: 'Apply',
  COUPON_CODE_APPLIED: 'Coupon applied successfully',
  //  Price Details
  PAYMENT_STATUS: 'Payment Status',
  PRICE_DETAILS: 'Payment Details',
  TOTAL: 'Total',
  TOTAL_QUES: 'Total Ques',
  TAX: 'GST',
  TOTAL_PAYABLE_AMOUNT: 'Total Payable Amount',
  BACK_BUTTON: 'Back',
  MAKE_PAYMENT: 'Make Payment',
  FAILED: 'Payment Failed',
  SUCCESSFUL: 'Payment Successful',
  TRY_AGAIN: 'Try Again',
  //  Transaction History
  VIEW_TRANSACTION_HISTORY: 'View Transaction History',
  TRANSACTION_TYPE: 'Transaction Type',
  CREDIT: 'Credit',
  DEBIT: 'Debit',
  CASHBACK: 'Cashback',
  DETAILS: 'Details',
  POSTED_JOB: 'Posted Jobs',
  SELECT_OPTION: '--select--',
  PLACEHOLDER_CITY: 'Select city',
  MINIMUM_SALARY: 'Minimum',
  MAXMIMUM_SALARY: 'Maximum',
  MINMAXERROR: 'Minimum must be smaller than or equals to Maximum',
  ANY_SALARY: 'Open for discussion',
  PER_ANNUN: ' PA',
  //  Candidate Steps
  LOCATION: 'Location',
  DESIRED: 'Desired',
  REQUIRES: 'Requires',
  FRESHER: 'Fresher',
  ANY_LOCATION: 'Any Location',
  YEAR: 'year',
  DESIRED_LOCATION: 'Desired Location',
  PERSONAL_INFORMATION: 'Personal Information',
  DESIRED_JOBS: 'Desired Jobs',
  EDUCATION: 'Education',
  JOB_HISTORY: 'Job History',
  BREAK: 'Break',
  TOOLS: 'Tools',
  TOOL: 'Tool',
  CERTIFICATIONS: 'Certifications',
  OTHER: 'Other',
  SCHOOLING: 'Schooling',
  TO_UNLOCK: 'to Unlock',
  DOWNLOAD_RESUME: 'Download Resume',
  GENERATE_RESUME: 'Generate Resume',
  // DOWNLOAD_AMC_RESUME: 'Download AMC Resume',
  RESUME_NOT_AVAILABLE: 'Resume not available',
  RESUME_AVAILABLE: 'Resume available after Unlock',
  DELETE_RESUME: 'Delete Resume',
  DOWNLOAD_ID_PROOF: 'Download ID Proof',
  DOWNLOAD_ICARD_PROOF: 'Download Company ID Proof',
  DELETE_ID_PROOF: 'Delete ID Proof',
  DELETE_ICARD_PROOF: 'Delete Company ID Proof',
  //  Company profile
  COMPANY_DETAILS: 'Company details',
  CONTACT_DETAILS: 'Contact details',
  EMPLOYER_JOBS: 'Employer jobs',
  OTHER_DETAILS: 'Other details',
  //  Post jobs
  ALT_LESS_RATING: 'Less rating scored in assessment on Align My Career',
  JOB_DETAILS: 'Job Details',
  SKILLS_AND_EXPERIENCE: 'Skills and Experience',
  RELEVANT_SKILLS: 'Add most relevant skills first',
  FIRST_TWO_OPTION:
    'Match the first 2 mentioned skills with the Candidates’ skill set for more results (recommended)',
  ALL_SKILLS_OPTION: 'Match all the above skills',
  ALL_SKILLS_OPTION_NOTE:
    'Note: This option will match candidates having all the mentioned skills, so the number of results might be very less, therefore not recommended.',
  POSTED: 'Posted',
  RESPONSE_AWAITED: '(Response awaited)',
  FULL_NAME: 'Full name',
  EMAIL: 'Email',
  SELECT: 'Select',
  MOBILE: 'Mobile',
  MOBILE_NUMBER: 'Mobile number',
  DATE_OF_BIRTH: 'Date of birth',
  GENDER: 'Gender',
  PASSPORT_NUMBER: 'Passport number',
  UPI: 'UPI ID',
  CITY: 'City',
  ADDRESS: 'Address',
  ENTER_YOUR_ADDRESS: 'Enter your address',
  ENTER_YOUR_QUESTION: 'Enter your question',
  ABOUT: 'About',
  WHAT_YOU_LIKE_IN_YOUR_COMPANY: 'What do you like in this company',
  WHAT_YOU_DOESNT_LIKE_IN_YOUR_COMPANY: `What do you dislike in this company`,
  PLACEHOLDER_ABOUT: 'Write a short introduction about yourself',
  RESUME: 'Resume',
  ID_PROOF: 'Photo Identity proof',
  ICARD_PROOF: 'Company Identity proof',
  INTRO_RECORD: 'Record Introduction',
  INTRODUCTION: 'Introduction',
  INTRO_HELPER_TEXT:
    'Employers give preference to profiles with clear and concise audio/video introduction. Please record your 60 second introduction.',
  AUDIO: 'Audio',
  RECORD_YOUR_ANSWER: 'Record Your Answer',
  REQUEST_FOR_INTERVIEW: 'Request Interview',
  VIDEO: 'Video',
  PLAY_INTRODUCTION: 'Play Introduction',
  LISTEN_ANSWER: 'Listen Answer',
  AUDIO_ANSWER_TITLE: 'Audio Answer',
  DELETE_INTRODUCTION: 'Delete Introduction',
  AUDIO_VIDEO_INSTRUCTIONS:
    'This introduction will be sent for review to our team and will be shared with the employers once approved.',
  OPEN_TO_WORK_AT_ANY_LOCATION: 'Open to work at any location',
  JOB_LOCATIONS: 'Job location(s)',
  JOB_LOCATION: 'Current Job location',
  EXPERIENCE: 'Experience',
  LEVEL: 'Level',
  IN_MONTHS: 'in months',
  JOB_TYPE: 'Job type',
  WORK_LOCATION: 'Mode of Work',
  NEGOTIABLE: 'Negotiable',
  ARE_YOU_A_FRESHER: 'I am a fresher',
  DESIRED_SALARY: 'Desired Salary',
  LAST_DRAWAN_SALARY: 'Last drawn salary',
  WORK_EXPERIENCE_IN_YEAR: 'Work experience in year(s)',
  SHIFT: 'Shift',
  WHEN_CAN_YOU_JOIN: 'When can you join',
  WILLINGNESS_TO_RELOCATE: 'Willingness to relocate',
  WOULD_LIKE_TO_ANSWER_FOR_CODING_QUESTIONS:
    'Would like to get coding questions?',
  EXPECTED_SALARY: 'Expected Salary',
  ASSESSMENT_SUBMITTED: 'Assessment submitted',
  INTERVIEW_SUBMITTED: 'Interview Submitted',
  HISTORY_OF_MOCK_INTERVIEW: 'Previous Mock Interviews',
  ASSESSMENT_CLOSING_STATMENT:
    'You may also close this tab and see the result in the Completed Assessments page.',
  INTERVIEW_CLOSING_STATEMENT:
    'You may close this tab and see the result in the Previous Mock Interviews section in Mock Interview page',
  RATING: 'Rating',
  WHERE: 'Where',
  LAST_USED: 'Last used',
  TOOLS_PLACEHOLDER: 'Tool e.g. Eclipse, UML, Excel, Gitlab, Postman etc',
  COLLEGE_UNIVERSITY: 'College/University',
  COLLEGE: 'College',
  COURSE: 'Course',
  FROM: 'From',
  DATE_PLACEHOLDER: '01/01/2022',
  TO: 'To',
  AT_PRESENT: 'Ongoing',
  PERCENTAGE: 'Percentage (%)',
  PERCENTAGE_NAME: 'Percentage',
  PERCENT_SIGN: '%',
  UPLOADED_ON: 'Uploaded on ',
  REMOVE: 'Remove',
  REMOVE_PHOTO: 'Remove Photo',
  PHOTO: 'Photo',
  UPLOAD: 'Upload',
  SUPPORTED_FORMATS: 'Supported formats: ',
  CGPA: 'CGPA',
  WORK_EXPERIENCE: 'Employment/Internship',
  JOB_TITLE: 'Job title',
  DESIGNATION: 'Designation',
  CURRENT_LOCATION: 'Current Location',
  DESIGNATION_TITLE: 'Designation title',
  TITLE: 'Title',
  COMPANY_NAME: 'Company',
  DEPARTMENT_NAME: 'Department Name',
  RATING_YOUR_COMPANY: 'Overall Rating',
  EMPLOYMENT_TYPE: 'Employment Type',
  COMPANY: 'Company',
  CTC: 'CTC (PA)',
  FULL_TIME: 'FULL_TIME',
  PART_TIME: 'PART_TIME',
  CONTRACTOR: 'CONTRACTOR',
  TEMPORARY: 'TEMPORARY',
  INTERN: 'INTERN',
  PA: 'PA',
  CTC_PLACEHOLDER: 'CTC',
  ROLES_RESPONBILITIES: 'Roles/Responsibilities',
  REASON: 'Reason',
  ANALYSICS: 'Analysis',
  SUGGESTED_IMPROVEMENT: 'Suggested Improvement',
  VISIBILITY: 'Visible to the employer',
  NAME: 'Name',
  DESCRIPTION: 'Description',
  ADVICE_FOR_COMPANY: 'Advice For Company',
  DATE: 'Date',
  VALIDITY_IN_MONTHS: 'Validity in months',
  ACHIEVEMENTS: 'Achievements',
  ACADEMIC_EXCELLENCE: 'Academic excellence',
  OPEN_SOURCE_COMMUNITY_CONTRIBUTION: 'Open source community contribution',
  PROJECTS: 'Projects',
  HOBBIES: 'Hobbies',
  PATENTS: 'Patents',
  LANGUAGE: 'Language',
  PROFILE_LINK: 'Profile url',
  LINKEDIN_LINK: 'LinkedIn url',
  GITHUB_LINK: 'GitHub url',
  ONLINE_PROFILE_URL: 'Online profile URL',
  LINKEDIN: 'LinkedIn',
  PDF: 'Pdf',
  IMAGE: 'Image',
  GITHUB: 'GitHub',
  SCHOOL_NAME: 'Name of School',
  CLASS_NAME: 'Class',
  BOARD: 'Board',
  SAVE_AS_DRAFT: 'Save As Draft',
  POST_JOB: 'Post Job',
  // University
  DASHBOARD: 'Dashboard',
  CAPITAL_YEAR: 'Year',
  REGISTRATION_DATE: 'Registration Date',
  ASSESSMENT_DATE: 'Assessment Date',
  OFFERED_DATE: 'Offered Date',
  HIRED_DATE: 'Hired Date',
  TOTAL_CANDIDATES: 'Total Candidates',
  TOTAL_ASSESSED_CANDIDATES: 'Total Assessed Candidates',
  TOTAL_NON_ASSESSED_CANDIDATES: 'Total Non-Assessed Candidates',
  REGISTERED_CANDIDATES: 'Registered Candidates',
  ASSESSED_CANDIDATES: 'Assessed Candidates',
  OFFERED_CANDIDATES: 'Offered Candidates',
  HIRED_CANDIDATES: 'Hired Candidates',
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  THIS_WEEK: 'This week',
  LAST_WEEK: 'Last week',
  THIS_MONTH: 'This month',
  LAST_MONTH: 'Last month',
  CUSTOM: 'Custom',
  CANDIDATE_COMPLETE_PROFILE_AND_INCREASE:
    'Complete profile and increase chances of getting hired',
  EMPLOYER_COMPLETE_PROFILE_AND_INCREASE:
    'Complete profile to hire the best talent instantly',
  POST_JOBS_PROFILE_MSG: 'Post jobs for enhanced visibility to the best talent',
  EXPIRE: 'Expires on',
  BACK: 'Back',
  NEXT: 'Next',
  POINT: 'credit point', // TBD - assuming that it will be shown as part of “Requires <1> point”
  JOB_DESCRIPTION: 'Job description',
  MINIMUM_QUALIFICATION: 'Minimum qualification',
  SALARY: 'Salary',
  OVERALL_EXPERIENCE: 'Overall experience',
  SKILLS_EXPERIENCE: 'Skills Experience',
  SKILL: 'Skill',
  NUMBER_OF_OPENINGS: 'Number of opening(s)',
  NUMBER_OF_OPENING: 'Number of opening',
  ALWAYS_HIRING: 'Always hiring',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  JOB_END_DATE: 'Job End Date',
  JOB_DEADLINE: 'Job deadline',
  JOB_EXPIRES_ON: 'Job expires on',
  PAY_BENEFITS: 'Pay benefits',
  REATTEMPT_INT: 'Reattempt Interval',
  SCORE_VALIDITY: 'Score Validity',
  DAYS: '(in days)',
  IN_SECS: '(in secs)',
  IN_MINS: '(in mins)',
  SECONDS: 'Seconds',
  RESEND_OTP_IN: 'Resend OTP in',
  AMC_ASSESSMENT: 'Assessments by AlignMyCareer',
  AI_AMC_ASSESSMENT: 'AI Assessments by AlignMyCareer',
  OTHER_BENEFITS: 'Other benefits',
  UPDATE_PROFILE: 'Update Profile',
  RESEND_OTP: 'Resend OTP',
  SHOW_PREVIEW: 'Show Preview',
  SHOW_DETAILS: 'Show Details',
  SEE_REVIEW: 'See Reviews',
  TIMELINE: 'Timeline',
  SHOW_TIMELINE: 'Show Timeline',
  YOUR_PROFILE_UPDATED: 'Your profile was updated',
  COMPANY_PROFILE: 'Company profile',
  LOGO: 'Logo',
  COMPANY_EMAIL_ID: 'Company email ID',
  COMPANY_TAGLINE: 'Company tagline',
  COMPANY_BRIEF: 'Company brief',
  GST_REGISTERED: 'Are you GST registered?',
  FIRST_SAVE_THE_ASSESSMENT:
    'To add questions, first save the assessment by clicking the Save button',
  GSTIN: 'GSTIN',
  GSTSTATE: 'GST State',
  FOUNDED_ON: 'Founded in',
  CONTACT_NUMBER: 'Contact number',
  WEBSITE: 'Website',
  COMPANY_SIZE: "Company's headcount",
  COMPANY_STAGE: 'Company stage',
  FUNDING: 'Funding status',
  ENGINEERS_SIZE: "Engineers' headcount",
  TECHNOLOGY_STACK: 'Technology stack',
  HOW_DID_YOU_HEAR_ABOUT_US: 'How did you hear about us?',
  PLEASE_MENTION_HERE: 'Please mention here',
  FACEBOOK: 'Facebook',
  FACEBOOK_LINK: 'Facebook url',
  TWITTER: 'Twitter',
  TWITTER_LINK: 'Twitter url',
  INSTAGRAM: 'Instagram',
  INSTAGRAM_LINK: 'Instagram url',
  ENTER_OPENING: 'Enter opening(s)',
  GST_NO: 'GST No',
  SELECT_THE_MONTH_YEAR: 'Select the month-year',
  COMPANY_ADDRESS: 'Company address',
  WWW_EXAMPLE_COM: 'www.example.com',
  HEAROTHERS: 'From where did you hear about us',
  ENTER_A_REASON: 'Enter a reason',
  IN_MONTH: 'In month(s)',
  ENTER: 'Enter %',
  ENTER_CGPA: 'Enter CGPA',
  SEARCH_CITY: 'Search City',
  ENTER_DESIRED_SALARY: 'Enter Desired salary',
  ENTER_DESIRED_LAST_MONTH_SALARY: 'Last drawn salary (per annum)',
  IN_YEAR: 'In year(s)',
  PLEASE_SELECT_CORRECT_DATES: 'please select correct dates',
  ADD: 'Add',
  UPDATE: 'Update',
  ALT_BLUETICK: 'Assessments verified by Align My Career',
  BLUETICK_TEXT: 'AMC Verified',
  SELFRATED_TEXT: 'Self Rated',
  ALT_DELETE_ICON: 'Delete icon',
  ALT_CONGRATULATIONS:
    'Congratulations on clearing the Assessment on Align My Career',
  ALT_CANDIDATE_PROFILE: 'Candidate Picture',
  ALT_BADGE:
    'Technical, Development Tools, Soft Skills badges for clearing assessment on Align My Career',
  EARNED_BADGES: 'Earned Badges',
  DELETE_ASSESSMENT_TITLE: 'Delete Assessment',
  WARNING: 'Warning!',
  CONFIRM: 'Confirm',
  DELETE_JOB_POST: 'Delete Job Posting',
  DELETE_JOB_POST_MSG: `Deleting this job posting will also delete the candidates' data who have applied for this posting. In case AI interview is enabled for this posting, it will also be deleted along with the candidates' data who have given the AI interview. Are you sure to continue?`,
  TIME_LIMIT_REACHED:
    'The AI interview duration will be more than 60 mins for the candidates. Are you sure to continue?',
  DELETE_ASSESSMENT_DIALOG_TEXT:
    'On deleting this assessment, it will be removed from the posted jobs’ prerequisites. Also, all the questions and assessed candidates’ data will be removed. Are you sure to delete this assessment?',
  GIVE_YOUR_ASSESSMENTS_AND_GET_BADGES: 'Take assessment and earn badges',
  BUY_POINTS_MESSAGE:
    'This action will only add points to your current plan. Points expiration date will remain same.',

  DISABLE_ASSESSMENT_TITLE: 'Disable Assessment',
  DISABLE_ASSESSMENT_TEXT:
    'On disabling this assessment, it will be removed from posted jobs’ prerequisites',
  ALT_EMPLOYER_LOGO: 'Company Logo',
  ALT_OTHER_PAGES: 'Other Pages',
  CHANGE: 'Change',
  DOCX_PDF_FILE_STR: 'docx, pdf, upto 1 MB',
  PNG_JPG_FILE_STR: 'png, jpg, jpeg, upto 100 KB',
  WAV: 'wav',
  MP4: 'mp4',
  JOB_UPDATED_SUCCESSFULLY: 'Job Updated Successfully',
  RESUME_PAGES: 'Recommendation: 1-2 pages',
  PASSWORD_SENT_SUCCESSFULLY: 'Password Sent Successfully',
  PASSWORD_RESET_LINK_SENT_SUCCESSFULLY:
    'Password reset link sent successfully',
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  PASSWORD: 'Password',
  FORGOT_PASSWORD: 'Forgot Password?',
  SUGGEST_NAME: 'Suggest Name',
  NEW_NAME: 'New Name',
  HIDE_PASSWORD: 'Hide Password',
  SHOW_PASSWORD: 'Show Password',
  SUGGEST_NAME_MSG: 'Please suggest another name for this company.',
  SUGGEST_NAME_NOTE:
    'This will be sent for review and will take few days to reflect.',
  PRE_REQUISITES_SECTION_NOTE: `Note: Technologies added in 'Assessments' need not be added here.`,
  NEW_TO_COMPANY_IN: 'New to AlignMyCareer?',
  REGISTER_FOR_FREE: 'Register for Free',
  ENTER_PASSWORD: 'Enter password',
  FORGOT_PASSWORD_: 'Forgot Password',
  ENTER_YOUR_REGISTERED_EMAIL: 'Enter your registered email ID',
  SEND: 'Send',
  CHANGE_PASSWORD: 'Change Password',
  NEW_PASSWORD: 'New Password',
  ENTER_NEW_PASSWORD: 'Enter new password',
  SUBMIT: 'Submit',
  REGISTER: 'Register',
  START_HIRING: 'Start Hiring',
  ENTER_EMAIL: 'Enter email',
  PHONE_NUMBER: 'Phone number',
  ENTER_PHONE_NUMBER: 'Enter phone number',
  ROLE: 'Role',
  ROLE_PLACEHOLDER: 'HR Manager, Project Manager, CEO etc',
  USER_AGREEMENT: 'User Agreement',
  PRIVACY_POLICY: 'Privacy Policy',
  AND: 'and',
  TERM_AND_CONDITIONS: 'Terms & Conditions.',
  CONTACT_SALE: 'Contact Sales',
  RENEW_PLAN: 'Renew Plan',
  RENEW: 'Renew',
  UPGRADE_PLAN: 'Upgrade Plan',
  ADD_POINTS: 'Add Points',
  SWITCH_PLAN: 'Switch Plan',
  ALREADY_ON_COMPANY_IN: 'Already on AlignMyCareer?',
  BY_CLICKING_AGREE_JOIN_YOU_AGREE_TO_THE_COMPANY_IN:
    'By clicking Register, you agree to our', //  TBD
  CONFIRM_TOKEN_REDIRECT_PAGE: 'Confirm Token Redirect Page',
  INVALID_REGISTRATION_TOKEN: 'Invalid Registration token',
  RESEND_TOKEN: 'Resend Token',
  YOUR_OLD_TOKEN:
    'Your old Token has expired, please check your email for the new token link',
  OLD_PASSWORD: 'Old Password',
  ENTER_OLD_PASSWORD: 'Enter old password',
  RESEND_EMAIL: 'Resend Email',
  ENTER_YOUR_REGISTER_EMAIL: 'Enter your registered email ID',
  PASSWORD_SEND_SUCCESSFULLY: 'Password sent Successfully',
  PLEASE_CHECK_REGISTERED_EMAIL_ADDRESS: 'Please check your email',
  RESEND_RESET_PASSWORD_LINK: 'Resend reset password link',
  CONFIRM_EMAIL_SUCCESSFULL: 'Email confirmation successful',
  YOUR_EMAIL_ID_IS_REGISTERD: 'Your email registered successfully',
  NOW_CLICK_GOING_TO_LOGIN_SECTION: 'Click to',
  PLEASE_VERIFY_YOUR_EMAIL: 'Please verify your email',
  ENTER_OTP_FROM_EMAIL: 'Enter the OTP sent on your registered email',
  ONCE_YOU_VERIFY_YOUR_EMAIL_ADDRESS:
    'After verification of email, you are all set to explore',
  RESEND_REGISTRATION_MAIL_SUCCESSFUL: 'Resend Registration email successful',
  DIDNT_RECEIVE_AN_EMAIL: "Didn't receive an email?",
  UNSUBSCRIBE_MSG: 'You have been unsubscribed from this notification.',
  UNSUBSCRIBE_LINK: ` to manage your notification's settings.`,
  UNSUBSCRIBE_ERROR:
    'An error occured while unsubscribing. Please try again later.',
  HERE: 'here',
  CLICK: 'Click ',
  //  Feedback Form
  FEEDBACK_FORM: 'Contact Us/Feedback form',
  COMMENTS: 'Comments',
  COMMENTS_PLACEHOLDER: 'Enter your comments',
  //  Login
  //  My candidates
  ALL: 'All',
  AVAILABILITY: 'Availability',
  //  candidate View Components
  FROM_TO: 'From-to',
  ROLES: 'Roles',
  // Banners Page && Notifications Page
  NOTIFICATIONS: 'Notifications',
  NO_NOTIFICATIONS: 'No New Notifications for you!!!',
  MANAGE_NOTIFICATIONS: 'Manage your Notifications',
  VIEW_SETTINGS: 'View Settings',
  // Add Users To Project
  PROJECT_NAME: 'Project Name',
  SAVE: 'Save',
  VERIFY: 'Verify',
  ADD_USERS_TO_PROJECT: 'Add Users in Project',
  TRANSFER_OWNERSHIP: 'Transfer Ownership',
  MARK_DELETE_PROJECT: 'Mark for Deletion',
  DELETE_PROJECT: 'Delete Project',
  REVERT_DELETE_PROJECT: 'Revert Project Deletion',
  REVERT_DELETE_ACCOUNT: 'Revert Account Deletion',
  CONTINUE: 'Continue',
  EMAIL_TRANSFER_MSG:
    'Please mention the email id of user, you would like to transfer project ownership:',
  EMAIL_TRANSFER_MSG_ACCOUNT:
    'Please mention the email id of user, you would like to transfer account ownership:',
  REQUEST_OWNERSHIP: 'Request Ownership',
  TRANSFER_OWNERSHIP_WARNING:
    'The current owner will be logged off and will need to log in again after this activity. The new owner will also need to manually log out. Proceed with caution.',
  REQUEST_OWNERSHIP_WARNING:
    'We will send a request to the project owner to grant you ownership of the project. Click OK to proceed.',
  REQUEST_OWNERSHIP_WARNING_ACCOUNT:
    'We will send a request to the account owner to grant you ownership of this account. Click OK to proceed.',
  MARK_DELETE_PROJECT_WARNING:
    'This activity will be informed to all the project users. Project will not be deleted immediately. You will receive an email after 2 days with a link to delete the project. Click OK to proceed.',
  DELETE_PROJECT_WARNING:
    'This activity will forever delete your project. Click OK to proceed',
  REVERT_DELETE_PROJECT_WARNING:
    'This activity will revert your project deletion request. Click OK to proceed',
  MANAGE_USERS: 'Manage users',
  ACTIVE: 'Active',
  GET_STARTED: 'Get Started',
  PRODUCT_AND_FEATURES: 'Product and features',
  FREE: 'Free*',
  FREE_ONE_MONTH: '* Free for one month',
  ADD_USER: 'Add User',
  UPDATE_ROLE: 'Update Role',
  UPDATE_QUESTION: 'Edit',
  REMOVE_USER: 'Remove User',
  REMOVE_QUESTION: 'Delete',
  PREVIEW_QUESTION: 'Preview',
  ENABLE_QUESTION: 'Enable/Disable',
  HELPER_TEXT_END_DATE: 'Till when can a candidate take this assessment',
  HELPER_TEXT_START_DATE:
    'From when can a candidate start taking this assessment',
  HELPER_TEXT_EXPRIY_TIME: 'Till when the score remains valid for candidate',
  HELPER_TEXT_NO_OF_ATTEMPT:
    'After how many days can a candidate again attempt this assessment',
  HELPER_TEXT_ENABLE_ASSESSMENT:
    'Enable this assessment to add it as a prerequisite in job posting',
  HELPER_TEXT_CODE_BLOCK: 'Click on this to write code',
  HELPER_TEXT_DURATION_OF_AI_ASSESSMENT: `Exact duration will depend on the candidate's experience and number of questions`,
  UPI_HELPER_TEXT:
    'UPI ID is used only for giving cashbacks as part of our referral offers. In case, you do not want to avail referral offers, you can keep this field as blank.',
  LAST_DRAWAN_SALARY_HELPER_TEXT:
    'Last drawn salary will not be shown to everyone. This will be shown only to employers who will show interest in your profile.',
  ONLY: 'Only',
  PURCHASE_PLAN: `You don't have any active plan so please purchase any plan then you can do your operation`,
  NO_ASSESSMENT_TEXT: 'No Assessments have been created',
  ENABLE_ASSESSMENT: 'Enable/Disable Assessment',
  EDIT_ASSESSMENT: 'Edit Assessment',
  CONFIG_AI_INTERVIEW: 'Configure AI Interview',
  DELETE_ASSESSMENT: 'Delete Assessment',
  PREVIEW_ASSESSMENT: 'Preview Assessment',
  PREVIEW: 'Preview',
  PREVIEW_QUESTIONS: 'Preview Questions',
  REMOVE_USER_MSG: 'Are you sure you want to remove this user from Project?',
  CANCEL: 'Cancel',
  DOWNLOAD: 'Download',
  INVOICE_DOWNLOAD_NOT_ALLOWED: `You don't have permission to download invoice`,
  JOIN_PROJECT: 'Join Project',
  HIRING_ROLE: 'Hiring Role',
  OWNER_ACCOUNT_MSG: 'Owner of this company profile is: ',
  CREATE_NEW_PROJECT: 'Create New Project',
  MY_PROJECTS: 'My Projects',
  COMPANY_PROJECTS: 'Company Projects',
  LEAVE_PROJECT: 'Leave Project',
  LEAVE_PROJECT_MSG: 'Are you sure you want to leave this project?',
  LOAD_SELECT: 'Load/Select',
  ACCEPT_USER: 'Accept User',
  REJECT_USER: 'Reject User',
  REJECT_USER_MSG:
    'Are you sure you want to prohibit this user from joining this Project?',
  MONTH: 'month',
  MONTHLY: 'Monthly',
  YEARLY: 'Yearly',
  DAY: 'day',
  MY_ASSESSMENT: 'My Assessments',
  MY_COMPANY_NAME: 'AlignMyCareer',
  ALL_RIGHT_RESERVED: 'All rights reserved',
  //  Header Navbar
  FIND_TALENT: 'Find Talent',
  FIND_JOBS: 'Find Jobs',
  MY_CANDIDATES: 'My Candidates',
  MY_JOBS: 'My Jobs',
  MY_PROFILE_ACTIVITY: 'My Profile Activity',
  PROJECT_DETAILS: 'Project Details',
  //  Home page
  SEARCH: 'Search',
  REMARK: 'Remark',
  EXECUTE: 'Execute',
  COMPILE_AND_RUN: 'Compile and Run',
  CONSTRAINTS: 'Constraints: ',
  REMARK_PLACEHOLDER: 'Enter the candidate %s',
  SIGNUP: 'Sign Up',
  LOGIN_HOME_PAGE: 'Log in',
  TRYAMCFORFREE: 'Try AMC For Free',
  STARTYOURFREETRAIL: 'Start your free trial',
  EMPLOYER_APPROACH_SECTION_TITLE:
    ' Ignite a Transformation in Your Organization with Skills as the Priority.',
  EMPLOYER_SKILLS_SECTION_TITLE:
    'Enhance your Skills with the assistance of AMC Expert Assessments.',
  CREATE_YOUR_ACCOUNT: 'Create Your Account',
  EXPLORE_ASSESSMENTS: 'Explore Assessments',
  EXPLORE_MOCK_INTERVIEW: 'Explore Mock Interviews',
  CANDIDATE_SKILL_SECTION_TITLE: 'Evolve and Grow with AlignMyCareer',
  EMPLOYER_SKILL_SECTION_TITLE: 'AI-Powered Skills-Emphasized Approach',
  JOB_SEEKER_CARD_LIST_TITLE:
    'Unlock your Boundless Potential with Assessed Skills.',
  FEATURED_COMPANIES: 'Featured Companies',
  SOTRY_SLIDER: 'Success Stories',
  TESTIMONAL: 'Testimonials',
  VIDEO_SECTION_TITLE: 'Our Videos',
  BLOG_SECTION_TITLE: 'Latest From Our Blogs',
  READ_MORE: 'READ BLOG',
  CASE_STUDIES: 'Case Studies',
  KNOW_MORE: 'Know More',
  WHY_ALIGN_MY_CAREER: 'Why AlignMyCareer?',
  HOME_PAGE_SLIDER_TEXT_ONE:
    'Get the Job that Aligns with your Skills and Dreams.',
  HOME_PAGE_SLIDER_DESCRIPTION_ONE:
    'Expert-designed, fast-growing platform - the perfect meeting place for tech talent and best-suited jobs opportunities.',
  HOME_PAGE_SLIDER_TITLE_TEXT_TWO:
    'Recruiting is a Breeze; Try AI based hiring!',
  HOME_PAGE_SLIDER_DESCRIPTION_TWO:
    'AlignMyCareer provides instant access to the perfect job-ready candidates that match your exact requirements, saving you invaluable money, time, and resources.',
  // Find Jobs Home Page
  PROFILE_COMPLETED: 'Profile completed',
  PROFILE_APPEARANCE: 'Profile Appearance',
  LAST_30_DAYS: '(last 30 days)',
  PROFILE_APPEARED: 'Your Profile Appeared in',
  JOB_COUNT_PERFORMANCE: 'Job Stats',
  MATCHING_JOBS: 'Matching Jobs',
  SEARCHES_APPEARANCES: 'Search Appearances',
  TOTAL_ASSESSMENT: 'Total Assessments',
  PROFILE_VIEWS: 'Profile Views',
  SHORTLISTED: 'Shortlisted',
  CANDIDATE_INTERACTIONS: 'Candidate Stats',
  JOB_OFFERED: 'Job Offered',
  ACCEPTED: 'Offers Accepted',
  REJECTED: 'Offers Rejected',
  HIRED: 'Hired',
  AUTO_APPLIED: 'Auto-Applied Jobs',
  PROFILE_PERFORMANCE: 'Profile Performance',
  UNLOCKS: 'Unlocks',
  SHORTISTS: 'Shortlists',
  INTERVIEWS: 'Interviews',
  //  Footer
  JOB_SEEKER: 'Job Seeker',
  EMPLOYER: 'Employer',
  SOCIAL: 'Social',
  FIND_JOB: 'Find Job',
  TOKEN_NOT_VALID: 'Oops! Token is not valid',
  EXPIRED_TOKEN: 'Oops! Token is expired',
  FEEDBACK: 'Feedback',
  GENERATE_NEW_TOKEN: 'Generate new Token',
  OFFER_ERROR: `Couldn't load the offers, try again later`,
  FOR_RECRUITERS: 'For Recruiters',
  FOR_JOB_SEEKERS: 'For Job seekers',
  // Password Reset Forgot Password
  PASSWORD_RESET_ATTEMPTS_EXCEEDED: 'Password reset attempts exceeded',
  USER_NOT_FOUND: 'User not Found',
  REMOVE_LOGO: 'Remove Logo',
  NO_MATCHING_RESULT_FOUND: 'No Matching Result Found...',
  CHANGE_YOUR_PREFERENCES: 'Change your search criteria to get more results.',
  NO_RESULT_FOUND: 'No result found',
  // For badges table
  EXPERT: 'Expert',
  PROFICIENT: 'Proficient',
  INTERMEDIATE: 'Intermediate',
  BEGINNER: 'Beginner',
  // Setting page
  SETTING_HEADING: 'Settings',
  SETTING_UPDATE: 'Change and update the settings',
  NOTIFICATIONS_SETTINGS: 'Notification Settings',
  BASIC_SETTING: 'Basic Settings',
  AUTO_APPLY: 'Auto Apply',
  AUTO_APPLY_DESCRIPTION: `Candidates have to make themselves "Available" by selecting this flag. This flag is automatically set to false after 30 days. This will allow only the interested candidates to appear in the employer's search`,
  SETTING_PASSWORD: 'Password',
  SETTING_CHANGE_PASSWORD: 'Change Password',
  SETTING_DELETE: 'Delete Account',
  SETTING_DELETE_MY_ACCOUNT: 'Delete My Account',
  MAIL_RECIPIENTS: 'Mail Recipients',
  MAIL_RECIPIENTS_DESCRIPTION:
    'Weekly updates on candidate reports will be sent to these email addresses.',
  MAX_EMAILS: 'Maximum 5 email addresses allowed',
  EMAILS_ENTER: 'Press Enter to add email',
  INVALID_EMAIL_ADDRESS: 'Invalid email address ',
  GLOBAL_NOTIFICATION_TITLE: 'Global Notification Settings',
  GLOBAL_NOTIFICATION_MSG:
    'Enable/Disable these settings to turn on/off all the notification types quickly.',
  LOCAL_NOTIFICATION_TITLE: 'Notification-wise Settings',
  LOCAL_NOTIFICATION_MSG:
    'Enable/Disable notification methods for specific notification types.',
  // otherDetails page
  ACHIEVEMENTS_PLACEHOLDER:
    'Awards, honours, recognition, participation in coding challenges like Hackathons etc.',
  ACADEMIC_EXCELLENCE_PLACEHOLDER:
    'Academic awards, honours at school/college etc.',
  PROJECTS_PLACEHOLDER:
    'Projects other than workplace projects: school/college/freelance projects etc.',
  HOBBIES_PLACEHOLDER:
    'Recreational/leisure activities like chess, reading, music, cooking etc.',
  PATENTS_PLACEHOLDER: 'Type, stage, date of patents filed…',
  OPEN_SOURCE_COMMUNITY_CONTRIBUTION_PLACEHOLDER:
    'Contribution to projects in open source environment…',
  LANGUAGES_PLACEHOLDER: 'Languages known: English, Hindi, Tamil, French etc.',
  // anomalyDetails warning dialog
  COPY_PASTE: 'Copy paste not allowed. This action will be reported.',
  FULLSCREEN: 'Exiting full screen not allowed. This action will be reported.',
  SWITCH_TAB: 'Switching tab not allowed. This action will be reported.',
  TURN_ON_CAMERA:
    'Camera permission is required to continue with the test. Please turn on the camera',
  TEST_NOT_AVAILABLE: 'Test not available',
  TWO_PERSON: 'Two person visible',
  PERSON_MISSING: 'No person visible',
  ANOTHER_DEVICE: 'Another device visible',
  RIGHT_CLICK: 'Right Click not allowed. This action will be reported.',
  NONE: 'No anomaly',
  ACCOUNT_APPROVAL_PENDING: 'Account Approval Pending',
  ACCOUNT_APPROVAL_PENDING_MSG:
    'Your account approval is pending. You will not be able to perform tasks like posting jobs, unlocking candidates and purchasing points. Please complete your profile so that your account can be approved at the earliest.',
  ACCOUNT_INACTIVE: 'Account Inactive',
  ACCOUNT_INACTIVE_MSG:
    'Your account is inactive. You will not be able to perform tasks like posting jobs, unlocking candidates and purchasing points. Please update your profile to activate your profile.',
  ACCOUNT_REJECTED: 'Account Rejected',
  ACCOUNT_REJECTED_MSG:
    'Your account could not be approved. Please register again with your official email address. You will now be logged out.',
  ASSESSMENT_LOADING: 'Your assessment is loading, please wait...',
  // download file
  ASSESSMENT_REPORT: 'assessmentreport.pdf',
  ASSESSMENT_REPORT_TITLE: 'Assessment Report',
  MOCK_INTERVIEW_REPORT: 'Mock Interview Report',
  ASSESSMENT_DOWNLOAD_FAIL:
    'Report generation in progress. Please try after some time.',
  AI_ANSWER_AUDIO_FAIL: 'An unexpected error occurred. Please try again later.',
  ASSESSMENT_DOWNLOAD_SUCCESSFUL: 'File downloaded successfully',
  PURCHASED_POINTS: 'Purchased Points',
  LOGIN_REGISTER: 'Login/Register',
  LINK_COPIED: 'Shareable link copied to clipboard',
  COPY_LINK_TOOLTIP: 'Click to copy the shareable link to clipboard',
  COPY_INVITE_LINK: 'Copy Link',
  OPEN_TAB_TOOLTIP: 'Click to open the details in new tab',
  RETAKE: 'Retake',
  CAPTURE: 'Capture',
  PICUTRE: 'Picture',
  USE_CAMERA: 'Use Camera',
  PROFILE_HELPER_TEXT:
    'On AlignMyCareer, we require members to use their real identities, so take or upload a photo of yourself.',
  LATEST_EDUCATION:
    'Add educations in reverse chronological order with latest education first',
  LATEST_JOB: 'Add jobs in reverse chronological order with latest job first',
  PROFILE_PHOTO_HELPER_TEXT:
    'a photo gives authenticity to your profile and helps people recognize you.',
  PROFILE_PHOTO_INSTRUCTIONS:
    'Take or upload a photo of yourself. Align My Career requires members to use their real identities on the platform.',
  ID_CARD_HELPER_TEXT:
    'a photo identity increases the reliability of your profile.',
  ID_CARD_INSTRUCTIONS:
    'Align My Career requires members to use their real identities on the platform. Please take or upload a photo of government-approved identity card like Aadhar card, PAN card, Passport etc.',
  COMPANY_ID_CARD_HELPER_TEXT:
    'help us verify your association with your company.',
  COMPANY_ID_CARD_INSTRUCTIONS:
    'Please take or upload a photo of your current company ID card to confirm that you are part of the mentioned company. This is only for the purpose of verification and won’t be shared with anyone. ',
  ASSESSMENT_PICTURE_HELPER_TEXT:
    'a selfie with a photo ID gives a bona fide status to your test attempt.',
  ASSESSMENT_PICTURE_INSTRUCTIONS:
    'Take and upload a photo of yourself holding a photo identity card like Aadhar card, PAN card, VoterID card, Driving License etc. Your face and the identity card should be in the same frame. This is for verification of the candidate who is taking the assessment.',
  // ADMIN
  GENERATE_SITEMAP: 'Generate Sitemap',
  UPDATE_SKILLS: 'Update Skills',
  UPDATE_TECH: 'Update Technology',
  ACTIONS: 'Actions',
  ANALYTICS: 'Analytics',
  DELETE_CAND: 'Delete Candidate',
  // ALT
  ALT_CUSTOM_NEXT_ARROW: 'Next',
  ALT_CUSTOM_PREVIOUS_ARROW: 'Previous',
  ALT_APPROACH_SECTION_HOME_IMAGE: 'Align My Career Approach',
  ALT_BENEFITS_SLIDER_IMAGE: 'Align My Career Benefits',
  ALT_CASE_STUDIES_IMAGE: 'Align My Career Case Studies',
  ALT_FEATURE_IMAGE_LEFT: 'Align My Career AI Assessments',
  ALT_FEATURE_IMAGE_RIGHT: 'Align My Career Mock Interviews',
  ALT_SKILLSET_IMAGE: 'Align My Career Skill Set',
  ALT_SKILL_TAB_LEFT: 'Arrow',
  ALT_BLOG_CALENDAR_IMAGE: 'Calendar',
  ALT_BLOG_IMAGE: 'Align My Career Blogs',
  ALT_CANDIDATEPAGE_IMAGE: 'Align My Career About',
  ALT_FEATURED_COMPANIES_IMAGE: 'Align My Career Featured Companies',
  ALT_JOB_SEEKER_CARD_IMAGE: 'Align My Career Job seeker benefits',
  ALT_STORIES_IMAGE: 'Align My Career Success Stories',
  ALT_TESTIMONAL_IMAGE: 'Align My Career Testimonals',
  ALT_ACHEIVESKILLS_IMAGE: 'Align My Career Recruiter benefits',
  ALT_PROUDUSERS_LOGO: 'Align My Career Proud Users',
  ALT_MYCAREER_IMAGE: 'Why Align My Career',
  ALT_SKILL_TAB_RIGHT: 'Align My Career USP',
  ALT_NO_RESULT_FOUND: 'No Matching record found',
  ALT_RE_ATTEMPT: 'Reattempt Assessments on Align My Career',
  ALT_ALIGNMYCAREER:
    'A cutting edge portal for job-seekers and recruiters that drastically reduces the time, effort, money, and resources needed',
  ALT_CAMERA: 'Remote Proctoring on Align My Career',
  ALT_CANDIDATE_PHOTO: 'Candidate Picture',
  ALT_JOB_LOGO: 'Post Jobs on Align My Career',
  ALT_ICON_UNLOCK:
    'Candidate profile preferred by Employers on Align My Career',
  ALT_ICON_INTERVIEW: 'Candidates interviewed by Employers  on Align My Career',
  ALT_ICON_ACCEPTED: 'Candidates Offered Job by Employers on Align My Career',
  ALT_ICON_JOBAPPLIED:
    'Candidates who have accepted Job Offers on Align My Career',
  ALT_ICON_HIRED: 'Candidates Hired by Employers on Align My Career',
  ALT_ICON_SHORTLISTED:
    'Candidates shortlisted by Employers on Align My Career',
  ALT_ICON_REJECTED:
    'Candidates who have rejected Job Offers on Align My Career',
  ALT_ICON_POSTED_JOB: 'Jobs posted by Employers on Align My Career',
  ALT_ICON_SEARCH_SEEN: 'Candidate Search Appearances on Align My Career',
  ALT_ICON_PROFILE_SEEN: 'Candidate Profile Views on Align My Career',
  ALT_ICON_ASSIGNMENT: 'Candidate taking Assessments on Align My Career',
  ALT_ICON_MATCHING_JOB: 'Matching jobs for Candidates on Align My Career',
  TITLE_HOME:
    'AlignMyCareer | AI job portal for India: AI interviews, skill-based search, top tech jobs, skilled candidates.',
  TITLE_DESC:
    'AlignMyCareer offers AI interviews, MCQs, and coding assessments to hire pre-assessed tech candidates efficiently. Discover top talent with technical and soft skills. Explore IT job opportunities and enhance career prospects. Post resume and apply online.',
  TITLE_ATTACHMENT: '| AlignMyCareer',
  TITLE_FINDJOBS: 'Search Jobs',
  TITLE_JOB_RECRUITERS: 'Job Recruiters',
  TITLE_ALIGNMYCAREER:
    'AlignMyCareer - Cutting edge portal for job-seekers and recruiters that drastically reduces the time, effort, money, and resources needed',
  TITLE_FINDCANDIDATES: 'Search Candidates / Talent / Job Seekers',
  TITLE_EMPLOYER_PROFILE: 'Employer Profile',
  TITLE_JOB_SEEKER: 'Job Seeker',
  TITLE_POST_JOB: 'Free Job Posting',
  TITLE_POSTED_JOB: 'Posted Jobs',
  TITLE_MY_CANDIDATES: 'Preferred Candidates',
  TITLE_JOB_STATUS: 'AI Matched Candidates / Applied Candidates for job',
  TITLE_EMPLOYER_DETAILS: 'Employer Details',
  TITLE_JOB_DETAILS: 'Job Details',
  TITLE_CANDIDATE_DETAILS: 'Candidate Details',
  TITLE_CANDIDATE_PROFILE: 'Candidate Profile',
  TITLE_MY_JOBS: 'Preferred Jobs',
  TITLE_MY_PROFILE_ACTIVITY:
    'Applied Profile Activity / Auto Applied Profile Activity',
  TITLE_ASSESSMENT: 'Assessments - Technical, SoftSkills, Development',
  TITLE_SETTING: 'Settings - Notification and Preferences',
  TITLE_OFFERS: 'Offers - Cashbacks, Free Credit Points',
  TITLE_NOTIFICATIONS: 'Notifications - Get Real time updates',
  TITLE_ALL_OFFERS: 'All Offers - Cashbacks, Free Credit Points',
  TITLE_START_ASSESSMENT: 'Start Assignment',
  TITLE_UNIV_STUDENTS: 'University Student',
  TITLE_UPDATE_PASSWORD: 'Update Password',
  TITLE_PAYMENT: 'Purchase Credit Points',
  TITLE_SEO_FIND_JOBS: 'Find Jobs / Opportunities',
  TITLE_FIND_JOBS_SEARCHES: 'Find Jobs / Opportunities',
  TITLE_SEO_FIND_CANDIDATES: 'Find Candidates / Talent / Job Seekers',
  TITLE_FIND_CANDIDATES_SEARCHES: 'Find Candidates / Talent / Job Seekers',
  TITLE_EMPLOYER_ASSESSEMENT: 'Employer Assessment',
  TITLE_UNSUBSCRIBE: 'Unsubscribe',
  TITLE_LOGIN: 'Login to unlock opportunities',
  TITLE_REGISTER: 'Free Register - Align My Career',
  TITLE_VERIFY_EMAIL: 'Verify Email',
  TITLE_CONFIRM_EMAIL: 'Confirm Email',
  TITLE_FORGOT_PASSWORD: 'Forgot Password',
  TITLE_RESEND_EMAIL: 'Resend Email',
  TITLE_CONFIRM: 'Confirm Registration',
  TITLE_CHANGE_PASSWORD: 'Change Password',
  TITLE_INFORMATION: 'Information',
  TITLE_ADMIN_ACTION: 'Admin Action',
  TITLE_ADMIN_ANALYTICS: 'Admin Analytics',
  TITLE_GUIDELINES: 'Assessment Guidelines',
  TITLE_COMPANY_EMPLOYEE_PROFILE: 'My Profile',
  TITLE_PROJECT_DETAILS: 'Project Details',
  TITLE_MY_PROJECTS: 'My Projects',
  TITLE_COMPANY_PROJECTS: 'Company Projects',
  TITLE_EMPLOYER_ASSESSMENT: 'Assessments',
  TITLE_ASSESSMENT_DETAILS: 'Assessment Details',
  TITLE_CREATE_EDIT_ASSESSMENT: 'Create/Edit Assessment',
  TITLE_MOCK_INTERVIEW_REPORT: 'Mock Interview Report',
  TITLE_PRICING_DASH_BOARD: 'Subscription Plans',
  TITLE_FAQS: 'FAQs',
  TITLE_MOCK_INTERVIEW: 'Mock Interview',
  TITLE_ASSESSED_CANDIDATE: 'Assessed Candidates',
  // Description
  DESC_MOCK_INTERIVIEW_REPORT: 'Mock Interview report',
  DESC_DEFAULT:
    'Quick, guarantee-based solution to hiring requirements of companies, providing instant access to job-ready, productive candidates. The best platform for job-seekers to find best-matched jobs quickly, and to upgrade skill levels through assessments.',
  DESC_FINDJOBS: 'Search Jobs on Align My Career',
  DESC_FINDCANDIDATES:
    'Search Candidates / Talent / Job Seekers on Align My Career',
  DESC_EMPLOYER_PROFILE: 'Employer Profile on Align My Career',
  DESC_POST_JOB: 'Free Job Posting on Align My Career',
  DESC_JOB_SEEKER: 'Job Seekers on Align My Career',
  DESC_JOB_RECRUTIES: 'Job Recruties ',
  DESC_POSTED_JOB: 'Posted Jobs',
  DESC_MY_CANDIDATES:
    'Candidates unlocked, shortlisted by employers on Align My Career',
  DESC_JOB_STATUS:
    'AI Matched Candidates / Applied Candidates for job on Align My Career',
  DESC_EMPLOYER_DETAILS: 'Employer Details',
  DESC_JOB_DETAILS: 'Job Details',
  DESC_ASSESSMENT_DETAILS: 'Assessment Details',
  DESC_CANDIDATE_DETAILS: 'Candidate Details',
  DESC_CANDIDATE_PROFILE: 'Candidate looking for job at Align My Career',
  DESC_MY_JOBS: 'Jobs shortlisted / applied by candidates on Align My Career',
  DESC_MY_PROFILE_ACTIVITY:
    'Activities done on candidate profiles by applying for jobs or directly by employers on Align My Career',
  DESC_ASSESSMENT:
    'Assessments - Technical, SoftSkills, Development on Align My Career',
  DESC_SETTING: 'Settings - Notification and Preferences on Align My Career',
  DESC_OFFERS: 'Offers - Cashbacks, Free Credit Points on Align My Career',
  DESC_NOTIFICATIONS:
    'Notifications - Get Real time updates on Align My Career',
  DESC_ALL_OFFERS:
    'All Offers - Cashbacks, Free Credit Points on Align My Career',
  DESC_START_ASSESSMENT: 'Start Assignment',
  DESC_UNIV_STUDENTS: 'University Student',
  DESC_UPDATE_PASSWORD: 'Update Password on Align My Career',
  DESC_PAYMENT: 'Purchase Credit Points on Align My Career',
  DESC_SEO_FIND_JOBS: 'Find Jobs / Opportunities on Align My Career',
  DESC_FIND_JOBS_SEARCHES: 'Find Jobs / Opportunities on Align My Career',
  DESC_SEO_FIND_CANDIDATES:
    'Find Candidates / Talent / Job Seekers on Align My Career',
  DESC_FIND_CANDIDATES_SEARCHES:
    'Find Candidates / Talent / Job Seekers on Align My Career',
  DESC_EMOLPYER_ASSESSMENT: 'Create Your Own Assessment',
  DESC_UNSUBSCRIBE: 'Unsubscribe from notifications on Align My Career',
  DESC_LOGIN: 'Login to unlock opportunities on Align My Career',
  DESC_REGISTER: 'Free Register - Align My Career',
  DESC_VERIFY_EMAIL: 'Verify Email on Align My Career',
  DESC_CONFIRM_EMAIL: 'Confirm Email on Align My Career',
  DESC_FORGOT_PASSWORD: 'Forgot Password on Align My Career',
  DESC_RESEND_EMAIL: 'Resend Email on Align My Career',
  DESC_CONFIRM: 'Confirm Registration on Align My Career',
  DESC_CHANGE_PASSWORD: 'Change Password on Align My Career',
  DESC_INFORMATION: 'Information',
  DESC_ADMIN_ACTION: 'Admin Action',
  DESC_ADMIN_ANALYTICS: 'Admin Analytics',
  DESC_GUIDELINES: 'Guidelines for taking assessments on Align My Career',
  DESC_COMPANY_EMPLOYEE_PROFILE: 'Company User Profile on Align My Career',
  DESC_PROJECT_DETAILS: 'Project Details on Align My Career',
  DESC_CERATE_ASSESSMENT: 'Create And Edit Your Own Assessment',
  DESC_MY_PROJECTS: 'My Projects on Align My Career',
  DESC_COMPANY_PROJECTS: 'Company Projects on Align My Career',
  DESC_ASSESSMENT_EMPLOYER: 'Custom Assessments on Align My Career',
  DESC_CREATE_EDIT_ASSESSMENT: 'Create custom assessments on Align My Career',
  DESC_ASSESSED_CANDIDATE: 'Assessed Candidates on Align My Career',
  DESC_PRICING_DASH_BOARD: 'Subscription Plans',
  DESC_FAQS: 'FAQs',
  DESC_MOCK_INTERVIEW: 'Mock Interview',
  // Job details dynamic title
  REMOTE_JOB: 'Remote Job',
  APPLYNOW: 'Apply Now',
  // Candidate details dynamic title
  CANDIDATE: 'Candidate',
  INTERNSHIP: 'Internship',
  YEAROFEXPERIENCE: 'year of experience',
  SUCCESS: 'Success',
  PATH: 'Path',
  STATE: 'State',
  MODE: 'Mode',
  BOTH: 'Both',
  JOB: 'Job',
  GENERATE_SKILLS: 'Generate Non-Verified Skills',
  GENERATE_COLLEGE: 'Generate Non-Verified Colleges',
  GENERATE_COMPANY: 'Generate Non-Verified Companies',
  DOMAIN: 'Domain',
  CANDIDATE_DOMAIN: 'e.g. Banking, Fintech, Telecom, Networking etc',
  COMPANY_ID: 'Company ID',
  UPDATE_DOMAIN: 'Update Domain',
  TYPE: 'Type',
  PLEASE_SELECT_TYPE: 'Please Select a Type',
  POLICY_TYPE: 'Policy Type',
  CONDITION_TYPE: 'Condition Type',
  USE_TEMPLATE: 'Use Template',
  EXPIRY_DATE: 'Expiry Date',
  SHOW_DAYS: 'Show Days',
  DELETE_DAYS: 'Delete Days',
  BANNER: 'Banner',
  ANNOUNCEMENT: 'Announcement',
  DISMISS: 'Dismiss',
  OFFER_TYPE: 'Offer Type',
  CAND_REGISTRATION: 'CAND_REGISTRATION',
  CAND_FIRST_ASSESSMENT: 'CAND_FIRST_ASSESSMENT',
  ADMIN_AMOUNT: 'Amount/Point',
  ENTITY_ID: 'Entity ID',
  BANK_TRANSACTION_TIME: 'Bank Transaction Time',
  REFFRAL_ID: 'Referal ID',
  REFERRER: ' Referrer',
  CAND_REFER_CAND_REGISTRATION: 'CAND_REFER_CAND_REGISTRATION',
  CAND_REFER_CAND_FIRST_ASSESSMENT: 'CAND_REFER_CAND_FIRST_ASSESSMENT',
  EMP_REFER_CAND_REGISTRATION: 'EMP_REFER_CAND_REGISTRATION',
  EMP_REFER_CAND_FIRST_ASSESSMENT: 'EMP_REFER_CAND_FIRST_ASSESSMENT',
  CAND_REFER_EMP_POINTS_PURCHASE: 'CAND_REFER_EMP_POINTS_PURCHASE',
  SELF: 'Self',
  ADMIN_REJECTED: 'Rejected',
  OPTION: 'Option',
  TEXTFIELD: 'Textfield',
  CREATE: 'Create',
  UPLOAD_COLLEGE: 'Upload College',
  UPLOAD_QUESTIONS: 'Upload Questions',
  UPDATE_VERIFIED_COLLEGES: 'Update Verified Colleges',
  UPDATE_VERIFIED_COMPANIES: 'Update Verified Companies',
  UPLOAD_WL_DOMAINS: 'Upload WL Domains',
  UPLOAD_BL_DOMAINS: 'Upload BL Domains',
  UNIVERSITY_NAME: 'University Name',
  UNIVERSITY: 'University',
  EMPLOYER_ID: 'Employer ID',
  CONFIGURATION_ID: 'Configuration Id',
  WARRANTY_STATUS: 'Warranty Status',
  WARRANTY_APPROVED: 'WARRANTY APPROVED',
  WARRANTY_REJECTED: 'WARRANTY REJECTED',
  PROJECT_ID: 'Project ID',
  CANDIDATE_ID: 'Candidate ID',
  CREDENTIAL_ID: 'Credential ID',
  APPROVED: 'APPROVED',
  APPROVAL_PENDING: 'APPROVAL_PENDING',
  APPROVED_CHANGE: 'APPROVED_CHANGE',
  APPROVAL_REJECTED: 'REJECTED',
  CAPITAL_SUCCESS: 'SUCCESS',
  CAPITAL_REJECTED: 'REJECTED',
  NOTIFICATION: 'Notification JSON',
  UPDATE_ROLE_SKILL: 'Update Skill/Role JSON',
  NOTIFICATION_TEMPLATE: 'Notification Template JSON',
  VIEW_MORE: 'View More',
  SIMILAR_CANDIDATES: ' Similar Candidates you might be interested in:',
  NO_SIMILAR_CANDIDATES: 'No Similar Candidates are present',
  SIMILAR_JOBS: ' Similar Jobs you might be interested in:',
  NO_SIMILAR_JOBS: 'No Similar Jobs are present',
  MORE_ASSESSMENTS: 'More Assessments from AlignMyCareer:',
  CLICK_TO_ADD_SKILL: "Click '+' button to add skill",
  ADD_SKILL: "Click 'Add' button to add skill",
  UPDATE_SKILL: "Click 'Update' button to update skill",
  ASSESSMENTONAMC: 'Assessment on Align My Career',
  INTERVIEWONAMC: 'Mock Interview on Align My Career',
  EMPLOYER_DETAILS_DESCRIPTION:
    'looking for candidates / job seekers on Align My Career',
  POSTED_BY: 'Jobs Posted by',
  ON_AMC: 'on Align My Career',
  REPORT: 'Report',
  REPORT_AMC: 'Report on Align My Career',
  I_UNDERSTAND: 'I Understand',
  PROCTOR_INSTRUCTIONS: 'Proctoring Instructions',
  UPLOAD_INDUSTRIES: 'Upload Industries',
  UPLOAD_JOB_TITLE: 'Upload Job Title',
  UPDATE_VERIFIED_INDUSTRIES: 'Update Verified Industries',
  UPDATE_VERIFIED_JOB_TITLES: 'Update Verified Job Titles',
  UPDATE_VERIFIED_TAGS: 'Update Verified Tags',
  UPDATE_VERIFIED_CERTIFICATES: 'Update Verified Certificates',
  GENERATE_NON_VERIFIED_INDUSTRIES: 'Generate Non-Verified Industries',
  GENERATE_NON_VERIFIED_JOB_TITLES: 'Generate Non-Verified Job Titles',
  GENERATE_NON_VERIFIED_TAGS: 'Generate Non-Verified Tags',
  GENERATE_NON_VERIFIED_CERTIFICATES: 'Generate Non-Verified Certificates',
  UPDATE_INDUSTRY: 'Update Industry',
  UPDATE_JOB_TITLE: 'Update Job Title',
  UPDATE_TAG: 'Update Tag',
  UPDATE_CERTIFICATE: 'Update Certificate',
  DOMAIN_MAX_LIMIT_VALIDATION: 'Only 5 domains can be added',
  TAG_MAX_LIMIT_VALIDATION: 'Only 10 tags can be added',
  TAGS: 'Tags',
  CERTIFICATE_LABEL:
    'Allow only those candidates who have done at least one of the matching certifications',
  CANDIDATE_TAGS:
    'Add tags to highlight your topics of interest e.g. softwaredeveloper, startup, qaautomation etc',
  CERTIFICATE_TAGS:
    'Add Certificate to highlight your interest e.g. softwaredeveloper, startup, qaautomation etc',
  EMPLOYER_TAGS:
    'Add tags to highlight this jobs key areas like cloudarchitect, devops, mobileapp, telecom etc ',
  UPLOAD_TAGS: 'Upload Tags',
  UPLOAD_CERTIFICATES: 'Upload Certificates',
  RELOAD_UI: 'Reload UI',
  SAVE_AND_CLOSE: 'Save & Close',
  WRITE_YOU_QUESTION_HERE: 'Write your question here',
  ALPHA_NUMERIC_VALIDATION: 'Please enter only alphanumeric characters',
  SHARE: 'Share',
  EXPLORE: 'Explore',
  CERTIFICATE_DOWNLOAD_FAIL:
    'Certificate generation in progress. Please try after some time.',
  DOWNLOAD_CERTIFICATE: 'Download Certificate',
  DOWNLOAD_REPORT: 'Download Report',
  VIEW_REPORT: 'View Report',
  EDIT: 'Edit',
  DELETE: 'Delete',
  ASSESSMENT_NAME: 'Assessment Name',
  NO_OF_QUESTIONS: 'No of Questions',
  SKILL_OR_TECH_AI_ASSESSMENT_NAME: 'Skill',
  TECHNOLOGY: 'Technology',
  IS_CODING_QUESTION: 'Is this a coding question?',
  ENABLE: 'Enable',
  CURRENT_EMPLOYE: 'Do you currently work here?',
  RECOMMEND_YOUR_FIREND: 'Would you like to recommend the company to others',
  ADD_QUESTION: 'Add question',
  UPDATE_QUESTION_BUTTON: 'Update question',
  DELETE_QUESION: 'Delete Question',
  ARE_YOU_SURE_WANT_TO_DELETE: 'Are you sure to delete this %s ?',
  ARE_YOU_SURE_WANT_TO_CONTNIUE: 'Are you sure want to continue?',
  CONTINUE_TEXT: `You haven't provided score for some of the parameters. Do you want to continue?`,
  QUESTION_STATEMENT: 'Question Statement',
  OPTIONS: 'Options',
  ANSWERS: 'Answer',
  CORRECT: 'Correct',
  AI_REASON: 'Reason',
  COMMENT: 'Comment',
  OVERALL_COMMENT: 'Overall Comment',
  RESULT: 'Result',
  HOW_SATISIFED_ARE_YOU_WITH_OUR_SCORE:
    'How satisfied are you with the score provided?',
  AI_REASON_PLACEHOLDER: 'Reason by the AI',
  ENTER_THE_REASON: 'Enter your reason ',
  AI_GENERATED_RESULT: 'AI Generated Result',
  ENTER_YOUR_ANSWER: 'Enter your answer',
  CATRGORY: 'Category',
  CATEGORY_PLACEHOLDER: 'OOPS, Concurrency, Multithreading etc..',
  EXPLANATION: 'Explanation',
  ENABLE_THE_QUESTION: 'Enable this Question',
  PREVIEW_QUESTION_BUTTON: 'Preview Question',
  PLEASE_SELECT_FROM_DROPDOWN: 'Please select from the dropdown',
  INVALID_DATE: 'Invalid Date',
  CANDIDATE_PROFILE_AI_DESCRIPTION_TEXT_PART_ONE:
    'Please fill other details, then',
  CANDIDATE_PROFLE_AI_DESCRIPTION_TEXT_PART_TWO:
    'to Autogenerate your profile by AI.',
  POST_JOB_AI_DESCRIPTION_TEXT_PART_ONE: 'Please fill other details, then',
  POST_JOB_AI_DESCRIPTION_TEXT_PART_TWO:
    'to Autogenerate the job description by AI.',
  CLICK_HERE_TO_GENERATE_ANSWER_BY_AI: 'to generate answer by AI',
  CLICK_HERE: 'click here',
  EDUCATION_TYPE: {
    ASSOCIATE_DEGREE: 'associate degree',
    BACHELOR_DEGREE: 'bachelor degree',
    POST_GRADUATE_DEGREE: 'postgraduate degree',
  },
  // WILL HAVE TO CHANGE
  ASSESSMENT_ZERO_COUNT: '0',
  ASSESSMENT_TYPE_NAME: {
    MCQ_ASSESSMENT: 'MCQ',
    CODING_ASSESSMENT: 'CODING',
    BOTH_MCQ_AND_CODING: 'MCQ & CODING',
  },
  CHARACTER_REMAINING: 'characters remaining',
  EXCEEDED_LIMIT: 'Exceeded the limit!',
  REPORT_QUESTION: 'Report Question',
  ADDED_SUCCESSFULLY: 'Added Successfully',
  UPDATE_SUCCESSFULLY: 'Updated Successfully',
  ONE_MONTH_AGO: 'one month ago',
  ONE_MONTH_PULS_AGO: ' 1+ months ago',
  MSG_AFTER_GD_GENERATE:
    '%s autogenerated by AI. Please proofread and customize it as per your need.',
  NO_SKILLS_HAVE_ADDED: 'No skills are present',
  NO_REVIEWS_FOUND: 'No Reviews Found',
  OVERALL_RATING: 'Overall Rating',
  OVERALL_SCORE: 'Overall Score',
  CATERGORY_RATINGS: 'Category Ratings',
  REVIEWS_FOUND: 'reviews found',
  LIKES: 'Likes',
  DISLIKES: 'Dislikes',
  STAR: 'star',
  SEARCH_BY_COMPANY_PLACEHOLDER: 'Search by company name',
  NO_REVIEWS_FOUND_TEXT: 'Be the first one to review %s',
  USEFUL: 'Useful',
  ASSESSEMENT_REPORT: 'Assessment Report',
  UNSUBSCRIBE_MAIL: 'Unsubscribe Mail',
  SUMMARY: 'Summary',
  TEST_DATE: 'Test Date',
  PROCTORING_INDEX: 'Proctoring Index',
  PROCTORING: 'Proctoring',
  CANDIDATE_IMAGE_WHILE_ASSESSMENT: 'Cam shot at starting of assessment',
  ASSESSMENT_REPORT_INTRODUCTION_TEXT: `This report provides the analysis of the assessment given by the candidate, examining their knowledge and skills through a series of questions. This report serves as a valuable tool for making informed decisions in recruitment, ensuring alignment with the organization's requirements and objectives.`,
  MOCK_INTERVIEW_REPORT_INTRODUCTION_TEXT: `This report presents an analysis of the candidate's mock interview, evaluating their knowledge and skills through a series of AI-generated questions. It includes an overall assessment of the interview performance, highlighting strengths, weaknesses, and offering suggestions for improvement in future interviews. Additionally, the report provides a detailed breakdown of the candidate's responses to each question, including sample answers and the rationale behind the scoring.`,
  ASSESSMENT_REPORT_INTRODUCTION_TEXT_TWO: `This report has two main sections. First section captures the proficiency of the candidate in the skill and tells the rating based on the answers given by the candidate. Second section captures the output of the different proctoring features used in the assessment and provides the count of each incident occurred during the test. It also gives the proctoring index (calculated using our AI algorithm), which denotes the incidents of presumably suspicious behavior by the candidate while taking the test. Higher the proctoring index, higher are the chances of the candidate having cheated during the test. Incidents are measured at a frequency of`,
  FREQ: 'freq',
  SECS: 'secs',
  ASSESSMENT_RESULT: '%s Result',
  MCQ_SECTION: 'MCQ Section',
  MCQ_SECTION_INTRO: `The following section shows complexity-wise percentage of
  correct answers:`,
  AI_SECTION: 'AI Section',
  AI_SECTION_INTRO: `The following section shows complexity-wise percentage of AI
  questions:`,
  CORRECT_ANSWERS: 'Correct answers (%)',
  TOTAL_ANSWERS: 'Total Questions (%)',
  CODING_SECTION: 'Coding Questions Section',
  FINAL_CODE: 'Final Code Submitted',
  COMPLIATION_STATS: 'Compilation Statistics',
  FINAL_COMPLIATION_STATS: 'Final Compilation Status',
  TOTOAL_COMPLIATION_ATTEMPTS: 'Total Compilation Attempts',
  SUCCESSFUL_COMPLIATION_ATTEMPTS: 'Successful Compilation Attempts',
  UNSUCCESSFUL_COMPLIATION_ATTEMPTS: 'Unsuccessful Compilation Attempts',
  TIME_TAKEN: 'Time taken to submit the question',
  AVERAGE_TIME_TAKEN: 'Average time taken between two compile',
  SCORE: 'Score',
  FUNCTIONAL_TEST_CASES_PASSED: 'Functional Test Cases Passed (%)',
  PERFORMANCE_TEST_CASES_PASSED: 'Performance Test Cases Passed (%)',
  INFORMATION: 'Information',
  INCIDENT: 'Incident',
  LAPTOP_DETECTED: 'Laptop detected',
  NO_OF_TIMES_LAPTOP_DETECTED: 'Number of times a laptop was detected',
  CELL_PHONE: 'Cell phone detected',
  NO_OF_TIMES_CELL_OF: 'Number of times a cell phone was detected',
  BOOK: 'Book detected',
  NO_OF_TIMES_BOOK: 'Number of times a book was detected',
  PERSON: 'Multiple persons detected',
  NO_OF_TIMES_PERSON: 'Number of times more than 1 person was detected',
  PERSONMISSING: 'Person Missing',
  NO_OF_TIMES_PERSON_MISSING: 'Number of times no person was detected',
  COPY_OPERATION: 'Copy Operation',
  NO_OF_COPY_OPERAIONS: `Number of times the candidate attempted to copy the
  question, indicating their intent to search the answer on
  the web`,
  RIGHT_CLICK_OPERATION: 'Right click operation',
  NO_OF_TIMES_RIGHT_CLICK_OPERATION: ` Number of times the candidate performed the right click
  action`,
  END_OF_SCREEN: 'End Full Screen',
  NO_OF_END_OF_SCREEN: `Number of times the candidate ended the full screen of
  assessment, indicating their intent to go to another
  tab/browser`,
  WEBCAM_OFF: 'Webcam Off',
  NO_OF_TIMES_WEBCAM_OFF:
    'Number of times the candidate switched off their webcam',
  PROCTORING_IMAGES: 'Proctoring Images',
  LEGEND_TWO: `High number of suspicious incidents detected; color coded on
  a single image`,
  LEGEND_THREE: `Extremely High number of suspicious incidents detected;
  color coded on a single image`,
  LEGEND_ONE: 'Low number of suspicious incidents detected',
  AUDIO_ANSWER: 'Audio Answer converted to text using AI',
  AI_ANSWER_AUDIO_TEXT: 'Click on mic to record your answer',
  START_RECORDING: 'Start Recording',
  STOP_RECORDING: 'Stop Recording',
  PAUSE_RECORDING: 'Pause Recording',
  RESUME_RECORDING: 'Resume Recording',
  SUBMIT_AND_NEXT: 'Submit & Next',
  AI_AUDIO_DIALOG_INSTRUCTION: `Please Record the Answer Carefully,because you can't record again.`,
  YOUR_ANSWER_RECORDED_SUCCESS: 'Your answer is successfully recorded',
  COPY_WRITE_TEXT:
    '© 2024 Jobihood Technologies Pvt. Ltd. All rights reserved.',
  MORE_MOCK_INTERVIEW: 'More Mock Interviews',
  PLEASE_FILL_EXPERIENCE: 'Please fill Experience',
  CANDIDATE_UNSUBSCRIBE_EMAIL_TEXT:
    'To unsubscribe from the mail notification, please',
  CANDIDATE_UNSUBSCRIBE_NOTIFICATION_SETTING:
    'To manage your notification settings, ',
  EMPLOYER_UNSUBSCRIBE_EMAIL_TEXT:
    'to manage your notification settings and unsubscribe from the mail notification.',
};
export default UI;
